export default {
  sample_no_production_date_message: '생산 날짜는 필수',
  free_comment: '프리 코멘트',
  round_list_rounds: '라운드',
  round_edit_round: '라운드 편집',
  round_new_round: '라운드 추가',
  round_remove_round: '이 라운드를 삭제하시겠습니까?',
  round_schedule: '계획',
  round_tasters_unselect_all: '모든 관능자 선택 취소',
  round_tasters_select_all: '모든 관능자 선택',
  round_close: '라운드 닫기',
  round_close_message: '이 라운드를 마무리하시겠습니까?',
  round_create_site_message:
    '자신의 공장 속하지 않는 라운드를 만들고 있습니다!',
  round_closed_message: '닫힌 라운드는 편집할 수 없습니다.',
  round_status_created: '작성',
  round_status_samples: '샘플',
  round_status_scheduled: '예정됨',
  round_status_tasters: '관능자',
  round_status_in_progress: '진행 중',
  round_status_finished: '완성됨',
  round_status_closed: '글로즈',
  round_schedule_start_finish_date:
    '시작 날짜는 종료 날짜보다 늦으면 안됩니다!',
  round_schedule_finish_date_no_start_date: '시작 날짜를 먼저 선택하세요!',
  round_disabled_discussion_tooltip:
    '완료되지 않은 라운드의 토론으로 이동할 수 없습니다.',
  round_delete_other_users_rounds:
    '사용자가 만들지 않은 라운드를 삭제할 수 있는 권한이 없습니다.',
  round_randomize_samples_order_button: '셔플',
  round_block_results_tooltip:
    '보고서 화면에서 이 라운드의 결과를 다운로드하지 못하도록 잠급니다. 버튼을 사용할 수 없는 경우 이 위치에서 라운드 결과를 잠글 수 있는 권한이 없습니다.',
  round_block_results_confirm:
    '이번 관능 보고서 다운로드를 금지/해제하시겠습니까?',
  round_block_results_no_privilege:
    '이 위치에서 라운드 결과를 금지/해제할 수 있는 권한이 없습니다.',
  sample_list_samples: '샘플',
  sample_edit_sample: '샘플 편집',
  sample_new_sample: '새로운 샘플',
  sample_remove_sample: '샘플 제거',
  sample_upload_document: '파일 업로드',
  sample_download_documents: '파일 다운로드',
  sample_tasted_dates: '관능 날짜',
  sample_upload_message: '여러 파일을 업로드하려면 여기에 추가하세요',
  sample_not_for_kpi: 'KPI에 적용되지 않음',
  sample_package: '패키지',
  sample_volume: '용량',
  sample_production_date: '생산 날짜',
  sample_expire_date: '만기일',
  sample_trial_identifier: '평가판 식별자',
  sample_project_name: '프로젝트 이름',
  sample_linked: '새로운 라운드에 연결',
  sample_expire_bigger_than_production:
    '생산 날짜는 만기일보다 늦을 수 없습니다!',
  sample_no_site_message:
    '샘플을 생성하려면 존, 국가 및 공장을 선택해야 합니다.',
  sample_no_brand_message: '브랜드를 선택해야 합니다.',
  sample_duplicated_kpi_message:
    '선택한 월, 브랜드 및 공장에는 KPI가 이미 있으므로 저장할 수 없습니다.',
  sample_duplicated_project_message:
    '이 사이트 및 브랜드에 대한 이 프로젝트 이름 및 평가판 식별자가 있는 샘플이 이미 있으므로 저장할 수 없습니다',
  sample_project_no_name_or_trial:
    '프로젝트 이름과 평가판 식별자는 필수 항목입니다.',
  sample_production_to_project_confirm_message:
    '이 샘플의 타입을 제품에서 프로젝트로 변경 중입니다. 계속하시겠습니까?',
  sample_project_to_production_confirm_message:
    '이 샘플의 타입을 프로젝트에서 제품으로 변경하고 있습니다. 계속하시겠습니까?',
  sample_no_volume_message:
    '샘플을 생성/편집할 때 패키지 및 용량은 필수입니다.',
  sample_id_code_message: '전체 배치 번호를 포함해 주세요.',
  sample_show_all_samples_hint:
    '오늘부터 6개월까지 생성된 샘플을 보여줍니다. 이전 샘플을 보려면 이 확인란을 선택합니다.',
  sample_show_all_samples_label: '모든 샘플 표시',
  package_duplicate: '이 이름을 가진 패키지가 이미 있습니다.',
  common_production: '생산',
  common_project: '프로젝트',
  group_list_groups: '그룹',
  group_edit_group: '그룹 편집',
  group_new_group: '그룹 추가',
  group_remove_group: '그룹 삭제',
  group_close_modal: '닫기',
  group_duplicated_user: '이 사용자는 이미 이 그룹에 추가되었습니다!',
  group_no_name_message: '그룹 이름은 필수입니다',
  group_no_zone_message: '존 선택해야 합니다.',
  group_no_users_message: '하나 이상의 관능자를 선택해야 합니다.',
  family_list_families: '유형',
  family_edit_family: '유형 편집',
  family_new_family: '새로운 유형',
  family_remove_family: '유형 삭제',
  family_has_no_english_name: '참고: 유형 이름은 영어여야 합니다.',
  brand_list_brands: '브랜드',
  brand_edit_brand: '브랜드 편집',
  brand_new_brand: '새로운 브랜드',
  brand_remove_brand: '브랜드 제거',
  brand_profile_picture: '맥주 프로필 사진 업로드',
  brand_upload_brand_logo: '브랜드 로고 업로드',
  brand_duplicate: '경고: 이 이름의 맥주가 이미 있습니다.',
  brand_has_no_english_name: '브랜드 이름이 하나 이상 있어야 합니다.',
  brand_upload_help_message: 'PNG 및 JPG 파일만',
  brand_has_no_product_type: '제품 타입을 선택해야 합니다.',
  package_list_packages: '패키지',
  package_edit_package: '패키지 편집',
  package_new_package: '새로운 패키지',
  package_remove_package: '패키지 삭제',
  package_has_no_english_name:
    '주의: 패키지 이름은 반드시 영어로 입력해야 합니다.',
  package_volumes: '용량',
  package_new_volume: '새로운 용량',
  package_remove_volume: '용량 삭제',
  degree_list_degrees: '강도',
  degree_edit_degree: '강도 편집',
  degree_new_degree: '강도 추가',
  degree_remove_degree: '강도 삭제',
  category_list_categories: '카테고리',
  category_edit_category: '플레이버 카테고리 편집',
  category_new_category: '새로운 플레이버 카테고리',
  category_remove_category: '플레이버 카테고리 삭제',
  comment_list_comments: '속성',
  comment_edit_comment: '플레이버 속성 편집',
  comment_new_comment: '새로운 플레이버 속성',
  comment_remove_comment: '플레이버 속성 삭제',
  user_list_users: '사용자',
  user_edit_user: '사용자 편집',
  user_new_user: '신규 ABI 계약자',
  user_export_users: '사용자 내보내기',
  user_list_profiles: '프로필',
  user_profile_name: '프로필 이름',
  user_profile_select: '프로필 선택',
  user_profile_remove: '프로필 제거',
  user_profile_save: '프로필 저장',
  user_remove_user: '사용자 삭제',
  user_use_terms: '수락된 사용 약관',
  report_list_report: '보고서',
  report_end_date: '종료일',
  common_privileges: '권한',
  common_abi_name: 'ABI 이름',
  common_id: 'ID',
  common_ranked: '순위',
  common_week: '주',
  common_weeks: '주',
  common_days: '일',
  common_category: '플레이버 카테고리',
  common_category_sample: '카테고리',
  common_degree: '강도',
  common_comment: '플레이버 속성',
  common_status: '상태',
  common_zone: '존',
  common_country: '국가',
  common_site: '공장',
  common_start_date: '시작 날짜',
  common_finish_date: '종료 날짜',
  common_round_type: '테스트 타입',
  common_round: '라운드',
  common_round_id: '라운드 ID',
  common_tasters: '관능자',
  common_taster: '관능자',
  common_sample_performance: '샘플 성능',
  common_taste: '관능',
  common_key_taster: '관능',
  common_sample: '샘플',
  common_samples: '샘플',
  common_sample_type: '샘플 타입',
  common_family: '스타일',
  common_brand: '브랜드',
  common_product_type: '제품 타입',
  common_temperature: '저장 조건',
  common_storage_times: '저장 시간',
  common_sku: 'SKU',
  common_batch: '아이디 코드',
  common_kpi_month: 'KPI 월',
  common_logo: '로고',
  common_name: '이름',
  common_notes: '메모',
  common_email: '이메일',
  common_language: '언어',
  common_terms_of_use: '이용약관',
  common_new: '새로운',
  common_profile: '프로필',
  common_filter: '사용자 필터',
  common_download: '다운로드',
  common_save: '저장',
  common_cancel: '취소',
  common_search_users: '사용자 검색',
  common_search_group: '그룹 검색',
  common_search_taster: '관능자 검색',
  common_no_data: '표시할 데이터가 없습니다.',
  common_loading: '로딩 중',
  common_cannot_be_empty: '{{field}}는 비워둘 수 없습니다.',
  common_select_option: '옵션 선택...',
  common_per_page: '페이지당 행 수',
  common_attributes: '속성',
  common_not_started: '미시작',
  common_in_progress: '진행 중',
  common_finished: '완성됨',
  common_start: '시작',
  common_detail: '세부 사항',
  common_position: '위치',
  common_average_key_score: '평균 점수',
  common_score: '점수',
  common_score_scale: '점수 표준',
  common_intensity: '강도',
  common_report_not_have_data: '선택한 라운드에는 결과가 없습니다.',
  month_0: '1월',
  month_1: '2월',
  month_2: '3월',
  month_3: '4월',
  month_4: '5월',
  month_5: '6월',
  month_6: '7월',
  month_7: '8월',
  month_8: '9월',
  month_9: '10월',
  month_10: '11월',
  month_11: '12월',
  dropdown_change_picture: '사진 변경',
  dropdown_language: '언어',
  dropdown_logout: '로그아웃',
  round_not_started: '미시작',
  round_in_progress: '진행 중',
  round_finished: '완성됨',
  round_filter_start: '시작',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: '라운드 나열',
  menu_tasting_session: '나의 관능',
  menu_panel_management: '관능 관리',
  menu_process_management: '프로세스 관리',
  menu_product_management: '제품 관리',
  menu_attributes_management: '속성 관리',
  tasting_finish_sample: '관능 완료',
  tasting_comments: '코멘트',
  tasting_beer_profile: '맥주 프로필',
  tasting_need_select_score_comments:
    '계속하려면 점수를 설정하고 코멘트를 하나 이상 추가해야 합니다...',
  tasting_finish_taste: '관능 완료',
  common_no_comments: '코멘트 없음',
  review_submit_title: '결과를 제출하시곘습니까?',
  review_submit_description: '추후에 편집할 수 없습니다.',
  review_finish_taste: '관능 완료',
  review_warning_sample_title: '관능하지 않은 샘플이 있습니다!',
  review_warning_sample_description: '관능하지 않고 계속하시겠습니까?',
  review_warning_sample_review: '검토',
  review_warning_sample_ok: '계속',
  review_warning_tie_sample_title: '샘플을 이미 묶었습니다!',
  review_warning_tie_sample_description:
    '순위를 매기지 않고는 계속할 수 없습니다!',
  review_warning_tie_sample_review: '검토',
  common_submit: '제출',
  common_discussion: '논의',
  common_not_informed: '알림이 없음',
  discussion_order_number: '위치별 순서',
  discussion_order_brewery: '공장별 순서',
  discussion_order_ranking: '순위별 순서',
  discussion_order_brand: '브랜드별 순서',
  discussion_order_score: '점수별 순서',
  discussion_order_key_score: '키 점수별 순서',
  discussion_order_overall_rank: '전체 순위별 순서',
  discussion_order_key_rank: '키 순위별 순서',
  discussion_export_error: '파일을 저장할 수 없습니다!',
  discussion_ranking: '순위',
  discussion_overall_ranking: '전체 순위',
  discussion_key_ranking: '키 순위',
  common_brewery: '공장',
  common_trial_identifier: 'ID 평가판',
  common_key_score: '키 점수',
  round_report: '엑셀로 내보내기',
  common_comments: '코멘트',
  message_you_cannot_delete_all_comments:
    '평가 결과를 편집할 때 모든 코멘트를 삭제할 수 없습니다.',
  common_message_empty_profile: '프로파일 이름이 비어 있을 수 없습니다.',
  common_message_out_score_range: '점수가 범위를 벗어난다.',
  tasting_need_select_score: '점수를 먼저 설정해야 합니다!',
  common_category_name: '카테고리',
  categories_new_category: '새로운 카테고리',
  categories_remove_category: '새 카테고리',
  categories_list_categories: '카테고리',
  categories_list_all_categories: '모든 카테고리',
  common_category_sample_name: '샘플',
  categories_new_category_sample: '새로운 샘플',
  categories_remove_category_sample: '샘플을 제거하시겠습니까',
  categories_list_categories_samples: '샘플',
  tasting_help: '관능 도움말',
  common_back: '되로가기',
  common_next: '다음',
  common_sample_id: '샘플 ID',
  common_sample_date: '샘플 날짜',
  common_duplicate: '복제',
  tasting_raw_materials: '원재료',
  tasting_example_sensory_risk:
    '주요 관능 리스크 예시: 예상한 입맛에 맞지 않다',
  tasting_procedure: '프로세스',
  tasting_sample_preparation: '샘플 준비',
  tasting_help_comments: '코멘트',
  common_collection_point: '수집 장소',
  common_collection_date: '수집일',
  common_brew_number: '맥주 번호/MES 번호',
  common_need_explain_nok: 'NOK를 가진 샘플에 코멘트 추가해야 합니다!',
  common_percent_ok: 'OK 비율',
  common_show_all: '모두 표시',
  common_import: '가져오기',
  common_download_template: '템플릿 다운로드',
  sample_import_message: 'xlsx',
  common_template: '템플릿',
  common_yes: '예',
  common_no: '아니요',
  common_need_volte_first: '먼저 결과 없이 샘플에 코멘트할 수 없습니다!',
  common_name_template: '템플릿 이름',
  interaction_log: '상호작용 로그',
  common_message_zone_empty: '존은 비어 있을 수 없습니다.',
  common_message_country_empty: '국가는 비어 있을 수 없습니다.',
  common_message_site_empty: '공장은 비어 있을 수 없습니다.',
  user_new_profile_save: '새 프로필이 생성됨',
  common_in_privileges: '특권',
  common_download_files: '파일 다운로드',
  pdf_viewer: 'PDF 보기',
  common_all: '모든',
  common_continue: '계속',
  common_minimum_score: '최소',
  common_maximum_score: '최대',
  common_average_score: '평균',
  file_upload_fail: '파일을 업로드하지 못했습니다. 다음 오류가 발생했습니다.',
  file_upload_success_with_error: '업로드 성공했지만 다음을 제외합니다:',
  missing_fields: '필드 누락',
  column_error: '열 오류',
  common_line: '행',
  file_no_samples: '파일에 샘플이 없습니다 !',
  file_success_upload: '파일 업로드 성공',
  file_duplicated_kpi_project: '중복된 KPI 또는 프로젝트',
  round_schedule_maximum_length: '라운드 이름의 최대 길이는 40자입니다.',
  sample_malt_varieties: '맥아 품종',
  error_sample_already_in_round: '샘플은 이미 라운드 중입니다.',
  malt_varieties_has_no_english_name:
    '하나 이상의 맥아 품종 이름이 있어야 합니다.',
  malt_varieties_duplicate: '참고: 이 이름을 가진 맥아 품종이 이미 있습니다.',
  malt_varieties_remove: '품종 삭제',
  malt_varieties_new: '새로운 품종',
  reports_no_dates: '시작 날짜와 종료 날짜는 필수입니다!',
  reports_date_range_message: '날짜 범위는 1년을 초과할 수 없습니다 !',
  round_save_select_site: '라운드를 저장하려면 공장을 선택해야 합니다.',
  common_error: '문제가 발생했습니다. 다시 한번 하십시오.',
  common_horizontal: '수평 레이아웃',
  common_unit: '구성 단위',
  common_supplier: '공급자',
  common_flavor: '플레이버',
  concentration_of_the_base_beer: '기본 맥주의 농도',
  threshold_aml_method: 'AML 방법(한계 상승 방법)',
  threshold_component_concentration: '성분 농도',
  threshold_sample_different_help: '어떤 샘플이 다른가요?',
  threshold_each_set_random_order: '각 집합에서 랜덤 순서입니다.',
  threshold_select_base_beer: '기본 맥주 선택',
  common_unity: '통일',
  common_finish_session: '관능 완료',
  review_submit_threshold: '저장하시겠습니까?',
  threshold_coder_wrong: '코드가 틀렸습니다. 올바른 코드를 입력하십시오!',
  threshold_didnt_enter_the_code: '코드를 입력하지 않았습니다!',
  code: '코드',
  common_batch_code: '배치 코드',
  threshold_samples_required: '기본 맥주 및 플레이버가 필요합니다.',
  threshold_information_helper_sample:
    '임계값 샘플 정보를 저장하려면 Save(저장) 버튼을 클릭하여 변경 내용을 유지하십시오.',
  code_digits: '할당된 3자리 코드를 입력합니다.',
  threshold_sample_spiked_with:
    '각 세트에서 다음과 같이 스파이크된 샘플을 선택합니다.',
  threshold_code_already_tasted:
    '이 코드는 이미 다른 사용자가 사용한 코드입니다. 다른 코드를 입력하십시오.',
  common_kpi_brands: 'KPI 브랜드',
  common_year: '년',
  common_new_kpi_brand: '새로운 KPI 브랜드',
  common_action: '액션',
  common_comment_by_category: '카테고리별 코멘트',
  common_alphabetic_comment: '알파벳 코멘트',
  common_name_pts_code: 'PTS 코드',
  common_name_pts_code_placeholder: '코드',
  common_name_process_item: '프로세스 항목',
  common_name_process_item_placeholder: '항목',
  common_global_brands: '글로벌 브랜드',
  common_global_brand: '글로벌 브랜드',
  common_target_global_brand: '글로벌 브랜드 대상',
  common_focus_brand: '포커스 브랜드',
  common_target_focus_brand: '포커스 브랜드 대상',
  common_strategic_brand: '전략 브랜드',
  common_target_strategic_brand: '전략 브랜드 대상',
  common_maximun_sites: '비교할 최대 공장 수:',
  common_state_reason_rejecting: '이 공장에 대한 거부 이유 설명',
  common_rejected_note: '거부된 노트',
  report_list_report_kpi: 'KPI 보고서',
  report_list_report_taste: '평가 보고서',
  select_brand_to_filter: '글로벌 브랜드를 선택하십시오!',
  select_year_to_filter: '연도를 선택하십시오!',
  kpi_only_numbers_warning: '목표 값의 숫자만 허용',
  kpi_max_number_range_warning: '대상 브랜드는 1에서 9 사이여야 합니다.',
  kpi_already_selected_brand_warning:
    '이 브랜드를 선택했습니다. 다른 브랜드를 선택하십시오.',
  kpi_no_kpi_informed_warning:
    'KPI값이 입력되지 않았습니다. 값을 추가해 주세요 .',
  kpi_select_year_warning: '연도 선택',
  kpi_select_site_warning: '공장 선택',
  kpi_existing_configuration_warning:
    '동일한 공장 및 연도의 kpi 값이 있습니다.',
  kpi_target_empty_warning: '브랜드 중 하나의 대상이 비어 있습니다.',
  kpi_saved_successfully: '값이 성공적으로 저장되었습니다!',
  kpi_submitted_successfully: '값이 성공적으로 제출되었습니다!',
  kpi_max_decimals_warning: '목표 값은 소수점 3자리 이내여야 합니다.',
  kpi_count_as_kpi_sample: 'KPI로 계산',
  samples_uncheck_kpi_sample_tooltip:
    '이 샘플을 KPI에 포함하지 않으려면 이 칸을 선택 취소합니다.',
  reports_mandatory_zone_filter: '존을 하나 이상 선택하십시오.',
  common_approve_reject: '승인/거부',
  common_approve: '승인',
  submission_type: '제출 유형',
  custom_scale_title: '사용자 정의 표준',
  custom_scale_grid_header_score: '평균 주요 점수',
  custom_scale_grid_header_ageind_scale: '강제 노화 표준',
  the_value_cannot_be_null: '값은 비워 둘 수 없습니다!',
  unique_id: '고유 ID',
  report_filter_rounds_description:
    '라운드 옵션을 로드하려면 필수 필드를 선택해야 합니다.',
  reports_mandatory_test_type: '테스트 유형을 선택하십시오.',
  round_reports_month_warning: '날짜 범위는 최대 6개월이어야 합니다.',
  zone_can_not_empty: '존은 비워 둘 수 없습니다 !',
  error_loading_data:
    '데이터를 로드하는 동안 오류가 발생했습니다. 연결을 확인하고 다시 로드하십시오.',
  flavor_identification: '플레이버 식별',
  beer_batch: '맥주 배치',
  list_of_beers: '맥주 목록',
  random: '랜덤',
  preview: '미리 보기',
  select_the_flavor: '플레이버 선택',
  flavor_threshold: '플레이버 임계값',
  input_digital_number: '디지털 번호 입력...',
  concentration: '농도',
  flavor_order: '플레이버 순서',
  last: '지난',
  tasting: '관능',
  confirmation: '확인',
  do_you_confirm_the_evaluation_of_this_sample:
    '이 샘플의 관능을 확인하시겠습니까?',
  confirm: '확인',
  evaluate: '관능',
  correct_answer: '정답',
  threshold: '임계값',
  let_a_comment_about_the_tasting: '관능에 대한 코멘트를 주세요.',
  send: '보내기',
  right_now: '지금',
  minute_ago: '분 전',
  hour_ago: '시간 전',
  day_before: '일 전',
  year_ago: '년 전',
  add_a_flavor: '플레이버 추가',
  soft_drinks: '청량 음료',
  soft_drinks_brand: '청량 음료 브랜드',
  soft_drinks_brand_target: '청량 음료 브랜드 목표',
  column_options: '열 옵션',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    '표시할 열을 선택합니다.',
  countdown: '카운트다운',
  less_than_five_minutes:
    '관능은 5분 후에 종료됩니다. 진행해야 할 경우 관리자에게 연락하여 시간을 연기하십시오.',
  the_countdown_to_the_evaluation_has_ended:
    '카운트다운이 종료되었으며 평가에 참여할 수 없습니다.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    '샘플 평가 완료하는 게에 얼마나 시간이 걸리는지 계산',
  only_six_flavors_can_be_added_at_most: '최대 6가지 맛만 추가할 수 있습니다 !',
  concentration_of_the_flavor: '플레이버 농도',
  input_value: '입력해 주세요.',
  Concentration_of_the_flavor_is_required: '플레이버 농도는 필수입니다.',
  previous_round: '이전 라운드',
  next_round: '다음 라운드',
  the_end_time_is_greater_than_the_current_time:
    '종료 시간은 현재 시간보다 커야 합니다. 현재 날짜와 동일한 날짜를 선택할 경우, 시간과 분을 먼저 선택합니다!',
  check_the_answer_at_the_end_of_the_countdown:
    '카운트다운이 0이 될 때까지 기다려서 결과를 확인하세요.',
  incremental_step: '증분 단계',
  set3_concentration: 'Set3 농도',
  concentration_increments_between_levels: '수준 간 농도 증가',
  incremental_set3_concentration_required:
    '증분 단계 및 Set3 농도가 필요합니다',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: '스프린트 번호는 0보다 커야 합니다.',
  sprint_notes_at_least_one_note:
    '스프린트 노트에는 최소한 하나의 노트가 있어야 합니다.',
  sprint_patch_notes_success_update:
    '스프린트 패치 노트가 성공적으로 업데이트되었습니다.',
  sprint_patch_notes_error_update: '노트를 저장할 수 없습니다.',
  update_sprint_patch_notes: '스프린트 패치 노트 업데이트',
  sprint_number: '스프린트 번호',
  sprint_patch_notes: '스프린트 패치 노트',
  note: '노트',
  select_all: '전체 선택',
  please_add_samples_first: '먼저 샘플을 추가하십시오',
  reverse: '뒤집다',
  score_scale: '테스트 추가',
  description: '설명',
  aditional_criteria: '추가 기준',
  new_test: '새로운 테스트',
  triangle_test: '삼각 테스트 이름',
  triangle_test_name: '삼각 테스트',
  you_can_not_set_three_identical_samples:
    '세 개의 동일한 옵션을 설정할 수 없습니다',
  its_mandatory_to_input_test_name: '테스트 이름을 기입해 주세요',
  its_mandatory_to_input_sample_information: '샘플 정보를 기입해 주세요.',
  attention: '팁',
  test_number: '테스트 번호',
  you_selected_the: '선택한 샘플은',
  next_sample: '다음 샘플',
  remove_triangle_test: '테스트 삭제',
  test: '테스트',
  confirm_remove: '테스트를 삭제하시겠습니까?',
  you_can_only_select_up_to_five: '최대 5개까지 선택할 수 있습니다',
  reports_mandatory_zone_country_site_filter: '존, 국가, 공장이 필요합니다!',
  reports_mandatory_brand_filter: '하나 이상의 브랜드를 선택합니다',
  download_label: '다운로드 버튼',
  reports_by_producing_site: '평가 보고서(공장/브랜드)',
  standard: '표준 값',
  input_test: '수동 입력',
  detecting: '탐지 중...',
  connected: '연결됨',
  current_taster: '현재 품평원',
  alcohol_concentration: '알코올 농도',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    '혈중 알코올 농도가 기준을 초과했습니다. 늦게 다시 테스트 진행해주세요!',
  alcohol_test_qualified: '알코올 검사 합격',
  configuration: '배치',
  input_result: '결과 입력',
  maximum_alcohol_concentration: '최대 알코올 농도',
  breathalyzer: '음주 측정기',
  please_input_a_positive_number_up_to_n_decimal_places:
    '소수점 {{field}자리까지 양수를 입력하십시오',
  you_must_input: '입력해야 합니다',
  driving: '운전 여부',
  test_time: '테스트 시간',
  input_type: '입력 방식',
  list_breathalyzer: '음주 측정기',
  Manual: '매뉴얼',
  Breathalyzer: '음주 측정기',
  you_do_not_have_privilege_to_export_this_report:
    '이 보고서를 내보낼 권한이 없습니다.',
  competitor_beer: '경쟁있는 맥주',
  split_round: '라운드 분할',
  reset: '리셋',
  how_many_rounds_splitting_do_you_want_to_make: '몇 라운드를 분할하시겠습니까',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    '이미 분할되었거나 진행 중인 라운드는 분할할 수 없습니다.',
  hide_sample_details: '샘플 정보 숨기기',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    '공장, 생산 일자, 브랜드, 맥아 품종 및 샘플 ID에 관한 정보는 관능자에게 숨겨집니다.',
  data_will_be_hidden_for_ok_nok: '모든 관련 데이터는 테스터에게 숨겨집니다.',
  you_can_not_add_duplicate_attributes: '중복적인 맛을 선택할 수 없습니다.',
  menu: '메뉴',
  link_breathalyzer: '연결된',
  not_link_breathalyzer: '미연결',
  breathalyzer_to_link: '음주 측정기 연결',
  breathalyzer_can_not_to_link_for_this_browser:
    'PC의 구글 크롬을 사용하여 음주 측정기 데이터를 읽으세요.',
  reopen: '다시 열기',
  round_reopen_message: '이 라운드를 다시 여시겠습니까',
  common_production_line: '공정',
  common_packaged_beer_data_code: '패키지 맥주 데이터 코드',
  common_masking_process_for_bottles: '병 마스킹 프로세스',
  common_str_number_days_over_aged: '초과 일수',
  common_over_aged_beer: '시간 초과 맥주',
  sample_successfully_swapped: '샘플이 성공적으로 교환되었습니다',
  scoring: '득점',
  configure_available_flavors: '맛에 옵션 설정',
  process_check: '온라인 관능',
  new_process_check: '온라인 관능 작성',
  edit_process_check: '온라인 관능 편집',
  remove_process_check: '온라인 관능 삭제',
  common_package: '패키지',
  common_department: '부서',
  common_shift: '교대조',
  common_tank: '탱크',
  common_oknok: 'OKNOK',
  common_remark: '비고',
  show_all_process_check: '모든 평가 데이터 보여주기',
  show_all_process_check_help_circle:
    '오늘부터 6개월 이내에 생성된 데이터를 표시합니다. 1년 내 데이터를 필요하면 이 확인란을 선택하세요.',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    '생플링 시간은 현재 시간보다 클 수 없습니다.',
  common_job_position: '포지션',
  decimal_sep: '소수점',
  is_swapped_to: '로 교환됨',
  continuous_typing: '연속 입력',
  continuous_typing_tips: "'강도'를 선택/입력한 후 '속성'을 연속 입력",
  review_warning_comment_title: '평가 입력하지 않은 샘플이 있습니다!',
  BSSI_Reports: 'BSSI 리포트',
  data_analysis_sip: '데이터 분석 - SIP',
  select_the_color: '색깔 선택',
  Color: '색깔',
  download_document: '파일 다운로드',
  Month_of_Discontinued_Production: '생산 중지 월',
  Suspension_List: '생산 중지 리스트',
  New_Suspension: '새 생산 중지신청',
  new_suspension_page: '새로운 생산 중지 정보',
  edit_suspension_page: '생산 중지 정보 편집',
  Brand_name: '브랜드명',
  List_Brewery_Production_Suspension: '공장 생산 중지 리스트',
  Upload_successfully: '업로드 성공',
  training_materials: '교육 자료를 보려면 클릭하십시오.',
  Remove_Suspension: '생산중지 정보 삭제: ',
  Add_Absence_Reason: '결석 이유 추가',
  Reason: '이유',
  You_have_duplicate_absence_dates: '결석 날짜가 중복됩니다',
  Remove_Absence: '결석 정보 삭제 : ',
  Absence_Title: '관능 결석한 시간과 이유를 선택해 주세요',
  Shipping_Site: '수취 공장',
  shipping_received: '도착 확인',
  receive_sample_alert: '샘플 수령 확인하시겠습니까?',
  receive_uncheck_alert: '선택한 것 취소하시겠습니까?',
  all_received: '모두 접수',
  not_all_received: '모두 접수 아님',
  not_for_shipping: '발송 필요 없음',
  receive_hint: '해당 샘플 접수 필요한 모든 공장을 선택해 주세요.',
  absence_reason: '결석 이유',
  Page1: '{{field}} 페이지',
  Search: '검색',
  page_total: '전체',
  start_date: '시작 날짜',
  end_date: '종료 날짜',
  ok_for_all: '전체 OK ',
  its_mandatory_to_select_sample_A_and_sample_B:
    '샘플A 와 샘플 B는 비워 둘 수 없습니다.',
  its_mandatory_to_select_sample_A: '샘플A는 비워 둘 수 없습니다. ',
  its_mandatory_to_select_sample_B: '샘플B는 비워 둘 수 없습니다. ',
  triangle_comment: '코멘트',
  select_sample: '샘플 선택',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    '샘플 날짜 필터는 자동적으로 최근 7일로 설정되며 선택한 기간에 따라 관능 데이터가 내보냅니다.',
  select_all_flavor: '모든 플레이버 선택',
  deselect_all: '모든 선택 취소',
  the_score_entered_is_invalid:
    '입력한 점수가 잘못되었습니다.분수표를 참고하여 점수를 다시 입력하십시오.',
  service_now: '클릭해서 티켓을 만들어 문제를 보고하고 도움을 받습니다.',
  service_now_tips:
    '시스템 사용 중 문제가 발생하거나 도움을 필요하실 때 여기에서 ServiceNow로 보고서를 보내주시면 저희가 연락드리겠습니다.',
  changes_you_made_may_not_be_saved:
    '변경 사항을 저장하지 못할 수 있습니다.계속하고 변경 사항을 잃으시겠습니까?',
  cannel_auto_save_tips:
    '관능 페이지 성능 향상 완료되었습니다. 관능 완료를 클릭하여 관능 결과를 저장하고 제출하는 것을 기억하시기 바랍니다.',
  add_samples: '라운드에 샘플 추가',
  copy_this_sample: `샘플 복제`,
  remove_this_sample: `샘플 제거`,
  multiple_selection_tips: `동일한 샘플 유형에 대해 한 번에 여러 개의 샘플을 선택할 수 있습니다. '+' 및 '-'를 클릭하여 시도해 보세요.`,
  all_add_once_tips: `'모두 선택' 버튼을 클릭하면 선택한 각 샘플 유형에 1개의 샘플이 자동으로 추가됩니다.`,
  copy_confirm_tip: `샘플을 복제하시겠습니까?`,
  user_non_abi_user: '새 임시 사용자',
  language_of_email_invitation: '이메일 초대 언어',
  add_non_abi_users_to_round:
    '임시 테스터에게는 특정 시음회에 참석할 수 있는 링크가 포함된 이메일 초대장이 발송됩니다.',
  change_non_abi_users_to_round:
    '초대받은 임시 사용자에게는 이 시음회에 참석할 수 있는 링크가 포함된 이메일 초대장이 발송됩니다.',
  guest: '게스트',
  email_to_all: '모두에게 이메일 보내기',
  send_email: '초대 이메일 보내기',
  temporary_non_abi_taster: '임시 테스터',
  user_type: '사용자 유형',
  email_invitation_delivery_request_sent: '초대 이메일 요청이 전송되었습니다.',
  incorrect_e_mail_formatting: '잘못된 이메일 서식',
  all_tasters: '모든 테스터',
  key_tasters: '핵심 테스터',
  non_key_tasters: '중요하지 않음 테스터',
  temporary: '임시 사용자',
  maximum_number_of_samples_is: `최대 샘플 수는 다음과 같습니다`,
  sample_added: '샘플 추가',
  click_to_view_scale_of_flavour_intensities: `자세한 강도 척도를 보려면 링크를 클릭하세요.`,
  list_next_week_plan: '다음 주 계획',
  common_time_zone: '시간대',
  common_last_response_time: `마지막 응답 시간`,
  new_week_plan: '새 주 계획',
  invited_week: '초대받은 주',
  email_language: `이메일 언어`,
  save_and_send_email: `이메일 저장 및 보내기`,
  common_start_time: '시작 시간',
  common_end_time: '종료 시간',
  session_name_email_subject: `세션 이름 / 이메일 제목`,
  location: '위치',
  edit_week_plan: '주 계획 변경',
  email_response: '이메일 응답',
  round_remove_plan: '요금제를 삭제하시겠습니까',
  view_week_plan: '주간 요금제 보기',
  are_you_confirming_to_send_the_confirmation_email: `확인 이메일 전송을 확인하시겠습니까`,
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation: `10분마다 시스템이 다음 주 플랜 초대의 이메일 응답을 업데이트합니다`,
  documents_upload_tips:
    '파일 업로드는 {{field}} 만 지원합니다',
};
