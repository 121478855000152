/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import LoginService from '../services/login/sso.service';
import loginSSOStyle from './loginSSO.module.scss';
import PropTypes from 'prop-types';
import logo from '../../logo.png';
import Spinner from '../../components/spinner/Spinner';
import { useUser } from '../../contexts/User';
import configs from '../../configs';
import Notify from '../../assets/notify.png';
import moment from 'moment';
import { DataDogRum } from '../../utils/dataDogRum';

const LoginSSO = props => {
  const loginService = new LoginService();

  const { i18n } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const { handleUser, handleLogged } = useUser();
  const [showNotify, setShowNotify] = useState(false)

  const handleLogin = () => {
    // Bypass pop-up blocker, have to open a new window as soon as you perform the click.
    const ssoWindow = window.open('about:blank', '_blank');
    setLoading(true);

    // Get SSO URL from back-end and open a new screen
    loginService.loginGetSSO().then(
      res => {
        ssoWindow.location.href = res.url;

        // Listen to HTTP pool and wait for user object
        let checker = setInterval(() => {
          loginService.checkAssert(res.request_id).then(res => {
            if (res.obj_user !== null) {
              clearInterval(checker);
              setLoading(false);
              assertLogin(res);
            }
          });
        }, 2000); // Interval checks

        setTimeout(() => {
          // Set timout if not finished
          setLoading(false);
          setInvalid('timeout');
          clearInterval(checker);
        }, 300000); // SSO checks timeout (5 minutes - 150 requests until assertion);
      },
      err => {
        console.error(err);
        setLoading(false);
        setInvalid('email');
      },
    );
  };

  const assertLogin = async ssoResult => {
    if (ssoResult && ssoResult.obj_user) {
      const showMenuTemp = !!(
        ssoResult.obj_user.arr_privileges &&
        ssoResult.obj_user.arr_privileges.length > 0
        && !(ssoResult.obj_user?.arr_privileges.length === 1
          && ssoResult.obj_user?.arr_privileges[0] === 'TASTER')
      );
      //console.log(showMenuTemp);
      // Save user session
      const login_date = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
      const unique_id = ssoResult.saml_response_result.user.attributes['http://schemas.microsoft.com/identity/claims/objectidentifier'][0]
      let userTemp = {
        ...ssoResult.obj_user,
        currentTab: 'menu_tasting_session',
        currentMenu: 'menu_tasting_session',
        showMenu: showMenuTemp,
        login_date,
        unique_id,
      };

      await handleUser(userTemp);
      handleLogged();
      i18n.changeLanguage(ssoResult.obj_user.str_language);
      DataDogRum(userTemp)
    } else {
      // Show error message with user SSO email (Ex.: User "example@ab-inbev.com" is not allowed to access.)
      setInvalid('email');
      setLoading(false);
      setEmail(ssoResult.str_email_sso);
    }
  };

  return (
    <div className={loginSSOStyle.loginContainer}>
      {showNotify &&
        <div className={loginSSOStyle.notice}>
          <img src={Notify} alt="" srcset="" />
        </div>
      }
      <div className={loginSSOStyle.loginBox} style={{ borderRadius: showNotify ? '0 10px 10px 0' : '10px' }}>
        <div className={loginSSOStyle.loginLogo}>
          <img src={logo} alt="ABInBev" height="50" />
        </div>
        {invalid == 'email' ? (
          <span className={loginSSOStyle.invalid}>
            <b>Error!</b>
            <br />
            User "{email}" is not allowed to use this application!
          </span>
        ) : null}
        {invalid == 'timeout' ? (
          <span className={loginSSOStyle.invalid}>
            <b>Error!</b>
            <br />
            SSO timeout, please try again!
          </span>
        ) : null}
        {loading ? (
          <Spinner />
        ) : (
          <Button label="SSO Login" onClick={handleLogin} confirm />
        )}
        {process.env.REACT_APP_STAGE !== 'prod' ? (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12', }}
          >
            TEST ENVIRONMENT &nbsp; v<b>{configs.val_version}</b>
          </div>
        ) : (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12' }}
          >
            v<b>{configs.val_version}</b>
          </div>
        )}
      </div>
    </div >
  );
};

LoginSSO.propTypes = {
  // onLogon: PropTypes.func.isRequired
};

export default LoginSSO;