import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import PaginationTable from './PaginationTable';
import { Search, Edit2, Trash, HelpCircle, List, Copy, Minus, Plus, AlertCircle, Key } from 'react-feather';
import {
  Button,
  CardImage,
  CardRow,
  DatePickerField,
  DropdownField,
  TextField,
  UploadButton,
} from '..';
import genericTableStyle from './genericTable.module.scss';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner/Spinner';
import Checkbox from '../checkbox/Checkbox';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import configs from '../../configs';
import editPermission from '../../assets/editPermission.svg';
import UserService from '../../modules/services/users/user.service';
import { ConfigProvider, DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import 'dayjs/locale/zh-cn';
import View from '../../assets/view.png';
import Download from '../../assets/download.svg';
import SelectAll from '../../assets/select_all.svg'
import SelectAllChanged from '../../assets/select_all_changed.svg'
import DuiHao from '../../assets/duihao.png'
import ChaHao from '../../assets/chahao.png'
import WenHao from '../../assets/wenhao.png'
const { RangePicker } = DatePicker;

const INITIAL_PAGE = 1;

const GenericTable = props => {
  const { t, i18n } = useTranslation();
  const [MAX_PAGE_ROWS, setMaxPageRows] = useState(0);
  const [checkInitialization, setCheckInitialization] = useState(true);
  const [filters, setFilters] = useState({});
  const [intRows, setIntRows] = useState(
    props.rowsPage ? props.rowsPage : MAX_PAGE_ROWS,
  );
  const [intPage, setIntPage] = useState(INITIAL_PAGE);
  const [intTotalRows, setIntTotalRows] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState({});
  const [tasteDate, setTasteDate] = useState('');
  const userService = new UserService();

  const receivedOpts = [
    {
      label: '0',
      value: t('all_received'),
    },
    {
      label: '1',
      value: t('not_all_received'),
    },
    {
      label: '2',
      value: t('not_for_shipping'),
    },
  ];

  useEffect(() => {
    if (props.handleFilteredRows) {
      props.handleFilteredRows(filteredRows);
    }
  }, [filteredRows])

  useEffect(() => {
    const filters = {};
    props.arrHeader.forEach(header => {
      filters[header.field] =
        header.cache === true ? handleGetStorage(header.fieldCache) : null;
    });
    if (props.customRanges) {
      filters['dat_start_date'] = dayjs(
        moment().subtract(
          props.defaultRangesDays ? props.defaultRangesDays - 1 : 2,
          'days',
        ),
      ).format('YYYY-MM-DD');
      filters['dat_end_date'] = dayjs(moment()).format('YYYY-MM-DD');
      let customRangesTemp = {};
      props.customRanges &&
        props.customRanges.forEach(day => {
          customRangesTemp[t('last_days', { field: day })] = [
            dayjs(moment().subtract(day - 1, 'days')),
            dayjs(moment()),
          ];
        });
      setCustomRanges(customRangesTemp);
    }
    setFilters(filters);

    if (!props.dataServer) {
      setFilteredRows(props.arrRow || []);
      setIntTotalRows(props.arrRow.length);
    }
    setTasteDate(Moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    if (props.dataServer && props.editPermission) {
      const filters = {};
      props.arrHeader.forEach(header => {
        filters[header.field] =
          header.cache === true ? handleGetStorage(header.fieldCache) : null;
      });

      handleDataServer(null, null, filters);
    }
  }, [props.editPermission]);

  useEffect(() => {
    if (props.arrRow) {
      setFilteredRows(props.arrRow);
      setIntTotalRows(props.arrRow.length);
      filter();
      if (props.onCheck) {
        handleChangePageCheckAll();
      }
      if (props.arrRow.length > 0 && props.rowsPage != 6) {
        userService.getUserRowPage().then(
          res => {
            if (res.type === 'success' && res.results) {
              let row_page =
                res.results.length > 0 ? res.results[0].int_row_page : 15;
              handleUserIntRows(props.arrRow.length, row_page);
            } else {
              handleUserIntRows(props.arrRow.length, 15);
            }
          },
          error => {
            handleUserIntRows(props.arrRow.length, 15);
          },
        );
      }
    }
  }, [props.arrRow]);

  useEffect(() => {
    if (props.onCheck && filteredRows?.length && !props.dataServer) {
      handleChangePageCheckAll();
    }
    if (props.dataServer) {
      const filters = {};
      props.arrHeader.forEach(header => {
        filters[header.field] =
          header.cache === true ? handleGetStorage(header.fieldCache) : null;
      });

      if (props.rowsPage != 6) {
        if (props.customRanges) {
          filters['dat_start_date'] = dayjs(
            moment().subtract(
              props.defaultRangesDays ? props.defaultRangesDays - 1 : 2,
              'days',
            ),
          ).format('YYYY-MM-DD');
          filters['dat_end_date'] = dayjs(moment()).format('YYYY-MM-DD');
          let customRangesTemp = {};
          props.customRanges &&
            props.customRanges.forEach(day => {
              customRangesTemp[t('last_days', { field: day })] = [
                dayjs(moment().subtract(day - 1, 'days')),
                dayjs(moment()),
              ];
            });
          setCustomRanges(customRangesTemp);
        }
        userService.getUserRowPage().then(
          res => {
            if (res.type === 'success' && res.results) {
              let row_page =
                res.results.length > 0 ? res.results[0].int_row_page : 15;
              if (checkInitialization) {
                let Avg = Math.ceil(intTotalRows / row_page);
                setIntRows(row_page);
                if (Avg < intPage && intTotalRows != 0) {
                  setIntPage(Avg);
                }
                setCheckInitialization(false);
                handleDataServer(null, row_page, filters);
              } else {
                handleDataServer(null, row_page, filters);
              }
            } else {
              handleSetIntRows(15);
              handleDataServer(null, 15, filters);
            }
          },
          error => {
            handleSetIntRows(15);
            handleDataServer(null, 15, filters);
          },
        );
      } else {
        handleDataServer(null, intRows, filters);
      }
    }
  }, [intRows]);

  const [customRanges, setCustomRanges] = useState([]);
  const [selectedValue, setSelectedValue] = useState([
    dayjs(
      moment().subtract(
        props.defaultRangesDays ? props.defaultRangesDays - 1 : 2,
        'days',
      ),
    ),
    dayjs(moment()),
  ]);

  const handleRangeChange = dates => {
    setSelectedValue(dates);
    let filtersTemp = filters;
    filtersTemp['dat_start_date'] = dayjs(dates[0]).format('YYYY-MM-DD');
    filtersTemp['dat_end_date'] = dayjs(dates[1]).format('YYYY-MM-DD');
    setFilters(filtersTemp);
    filter();
  };

  const handleUserIntRows = (TotalRows, Rows) => {
    let Avg = Math.ceil(TotalRows / Rows);
    setIntRows(Rows);
    if (Avg < intPage) {
      setIntPage(Avg);
    }
  };

  const handleChangePageCheckAll = () => {
    if (
      filteredRows?.length &&
      !filteredRows.some(
        (obj, index) =>
          index >= intPage * intRows - intRows &&
          index < intPage * intRows &&
          !obj.bol_check,
      )
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const handleSaveStorage = useCallback(
    _.debounce(
      (field, value) => localStorage.setItem(field, JSON.stringify(value)),
      550,
    ),
    [],
  );

  const handleGetStorage = field => {
    let ret = JSON.parse(localStorage.getItem(field));
    return ret ? ret : null;
  };

  const goToPage = useCallback((intPage, intRows, filter) => {
    setIntPage(intPage);
    if (props.dataServer) {
      handleDataServer(intPage, intRows, filter);
    }
  }, []);

  const handleDataServer = (page, rows, fields, state) => {
    setLoading(true);
    if (props.dataServer) {
      props
        .dataServer({
          page: page || intPage,
          size: rows || intRows,
          fields: fields || filters,
        })
        .then(res => {
          if (props.handleSetSamplesPag && !state) {
            props.handleSetSamplesPag(res.data);
          }
          setFilteredRows(res.data);
          setIntTotalRows(res.total);
          setCheckAll(false);
          setLoading(false);
        });
    }
  };

  const handleExportReport = () => {
    setLoading(true);
    props.exportService({ fields: filters }).then(res => {
      if (res.status === 'success' && res.file) {
        setLoading(false);
        let a = document.createElement('a');
        a.href = configs.file_api_url + '/v1/' + res.file;
        a.download = res.file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setLoading(false);
        window.alert(res.message);
      }
    });
  };

  const handleValue = async (evt, row) => {
    const { name, value } = evt.target;
    const upDateValue = { value: value, name: name, row: row };
    props.upDateValue(upDateValue).then(res => {
      if (res) {
        handleDataServer(intPage, intRows, filters);
      }
    });
  };

  const renderCellContent = (header, row, index) => {
    if (
      header &&
      (header.action || header.onMouseEnter || header.onMouseLeave)
    ) {
      if (header.type === 'button') {
        switch (header.ruleToShow) {
          case 'NOT_NULL':
            if (row[header.field]) {
              return (
                <>
                  {!!header.hideElement && header.hideElement(row) ? null : (
                    <>
                      <Button
                        label={header.description}
                        onClick={
                          header.action
                            ? () => {
                              handleAction(header, row);
                            }
                            : null
                        }
                        confirm
                        readOnly={
                          header.buttonReadOnly
                            ? header.buttonReadOnly(row)
                            : false
                        }
                        dataTip={header.dataTip ? header.dataTip(row) : ''}
                      />

                      {header.dataTip && header.dataTip(row) && (
                        <ReactTooltip />
                      )}
                    </>
                  )}
                </>
              );
            }
            break;
          case 'NULL':
            if (row[header.field] === null) {
              return (
                <>
                  {(!!header.hideElement && header.hideElement(row)) ||
                    (row.str_round_type === 'Flavor ID' &&
                      header.description === t('common_discussion')) ||
                    (row.str_round_type === 'Triangle Test' &&
                      header.description === t('common_discussion')) ? null : (
                    <>
                      <Button
                        label={header.description ? header.description : ''}
                        title={header.title ? header.title : null}
                        onClick={
                          header.action
                            ? async () => {
                              await handleAction(header, row);
                              if (props.dataServer && header.refresh) {
                                handleDataServer(intPage, intRows, filters);
                              }
                            }
                            : null
                        }
                        confirm={header.className ? false : true}
                        className={header.className ? header.className : ''}
                        readOnly={
                          header.buttonReadOnly
                            ? header.buttonReadOnly(row)
                            : false
                        }
                        dataTip={header.dataTip ? header.dataTip(row) : ''}
                      >
                        {header.content ? header.content : ''}
                      </Button>

                      {header.dataTip && header.dataTip(row) && (
                        <ReactTooltip />
                      )}
                    </>
                  )}
                </>
              );
            }
            if (row.dat_finish_real !== null && header.reOpenAction) {
              return (
                <>
                  <Button
                    label={t('reopen')}
                    onClick={async () => {
                      await header.reOpenAction(row);
                      if (props.dataServer) {
                        handleDataServer(intPage, intRows, filters);
                      }
                    }}
                    confirm={header.className ? false : true}
                    className={header.className ? header.className : ''}
                    readOnly={
                      header.buttonReadOnly ? header.buttonReadOnly(row) : false
                    }
                    dataTip={header.dataTip ? header.dataTip(row) : ''}
                  >
                    {header.content ? header.content : ''}
                  </Button>

                  {header.dataTip && header.dataTip(row) && <ReactTooltip />}
                </>
              );
            }
            break;
          case 'Upload': {
            return (
              <div
                style={{
                  width: 'auto',
                  minWidth: '155px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <UploadButton
                  name={`str_file${index}`}
                  label={t('sample_upload_document')}
                  margin="0px 10px 0px 0px"
                  allowExtension={['.jpeg', '.jpg', '.png', '.pdf', '.eml']}
                  multiple={true}
                  maxSize={5242880}
                  onUpload={e => {
                    handleValue(e, row);
                  }}
                />
                <AlertCircle
                  data-tip={
                    t(
                      'documents_upload_tips', {
                      field: '.jpeg, .jpg, .png, .pdf, .eml'
                    }
                    ) + '.'
                  }
                  className={
                    genericTableStyle.helpCircle
                  }
                />
                <ReactTooltip
                  effect="solid"
                  place="top"
                  type="warning"
                // className={editBrandStyle.tooltip}
                />
              </div>
            );
          }
          case 'Download': {
            if (row.str_file === null || row.str_file === '') {
              return <></>;
            } else {
              return (
                <>
                  <Button
                    blue
                    download
                    width="auto"
                    height="40px"
                    label={t('download_document')}
                    onClick={
                      header.action
                        ? () => {
                          handleAction(header, row);
                        }
                        : null
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '155px',
                      whiteSpace: 'nowrap',
                      padding: '10px 5px 10px 15px',
                    }}
                  ></Button>
                </>
              );
            }
          }
          case 'EQUAL':
            if (row[header.field] === header.equal) {
              return (
                <>
                  <Button
                    label={header.description ? header.description : ''}
                    title={header.title ? header.title : null}
                    onClick={
                      header.action
                        ? () => {
                          handleAction(header, row);
                        }
                        : null
                    }
                    confirm={header.className ? false : true}
                    className={header.className ? header.className : ''}
                    readOnly={
                      header.buttonReadOnly ? header.buttonReadOnly(row) : false
                    }
                    dataTip={header.dataTip ? header.dataTip(row) : ''}
                  >
                    {header.content ? header.content : ''}
                  </Button>

                  {header.dataTip && header.dataTip(row) && <ReactTooltip />}
                </>
              );
            }
            break;
          default:
            return null;
        }
        return null;
      } else if (header.type === 'multi-action' && header.action) {
        const object = row[header.data];

        return object?.map((item, i) => (
          <React.Fragment key={i}>
            <span
              key={`${index}-${item.key}`}
              style={{ margin: '0px 5px 0px 0px' }}
              className={
                !header.activation ||
                  (header.activation && header.activation(item))
                  ? genericTableStyle.actionCell
                  : null
              }
              onClick={
                header.action &&
                  (!header.activation ||
                    (header.activation && header.activation(item)))
                  ? header.action(row, index, item.value)
                  : null
              }
            >
              {header.shouldTranslate
                ? item[header.field]
                  ? t(item[header.field])
                  : t(item.label)
                : item[header.field]
                  ? item[header.field]
                  : item.label}
            </span>
            <br />
          </React.Fragment>
        ));
      } else if (header.type === 'template_hidden' && header.action) {
        if (header.isShow) {
          return (
            <div>
              <input
                type="checkbox"
                checked={row[header.field]}
                onChange={() => {
                  props.handleToggleChange(row, index);
                }}
                className={genericTableStyle.checke}
              />
            </div>
          );
        }
        if (header.isConfirm) {
          return (
            <div>
              <input
                type="checkbox"
                checked={row[header.field]}
                onChange={() => {
                  props.handleToggleChange(row, index);
                }}
                className={genericTableStyle.checkConfirm}
              />
            </div>
          )
        }
        if (row.str_round_type !== 'Threshold' || header.notSearchable) {
          return (
            <>
              <div className={header.className ? header.className : ''}>
                <Button
                  transparent
                  title={header.title ? header.title : null}
                  //onClick={header.action ? header.action(row, index) : null}
                  onClick={
                    header.action
                      ? header.hasAction
                        ? () => {
                          handleAction(header, row);
                        }
                        : header.action(row, index)
                      : null
                  }
                  confirm={header.className ? false : true}
                  onMouseEnter={
                    header.onMouseEnter ? header.onMouseEnter(row, index) : null
                  }
                  onMouseLeave={
                    header.onMouseLeave ? header.onMouseLeave(row, index) : null
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: header.buttonWidth,
                  }}
                >
                  <div>
                    {header.content
                      ? row[header.is_true] === header.content_compare //t("common_template")
                        ? header.content_template
                        : header.content
                      : ''}
                  </div>
                  <div>{row[header.fieldToSearch] || ''}</div>
                </Button>
              </div>
            </>
          );
        } else {
          return '';
        }
      } else if (header.type === 'ShippingReceived' && header.action) {
        const object = row[header.data];
        return object?.map(item => (
          <>
            <input
              type="checkbox"
              style={{
                verticalAlign: 'middle',
                zoom: '120%',
                marginLeft: '10px',
              }}
              disabled={!item.bol_operate}
              checked={item.bol_receive}
              onChange={() => {
                props.handleReceived(item, row);
              }}
            />
            <span
              style={{
                margin: '0px 5px 5px 5px',
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
              }}
            >
              {item.str_name}
            </span>
            <br />
          </>
        ));
      } else {
        return (
          <span
            className={genericTableStyle.actionCell}
            onClick={header.action ? header.action(row, index) : null}
            onMouseEnter={
              header.onMouseEnter ? header.onMouseEnter(row, index) : null
            }
            onMouseLeave={
              header.onMouseLeave ? header.onMouseLeave(row, index) : null
            }
          >
            {header.shouldTranslate ? t(row[header.field]) : row[header.field]}
          </span>
        );
      }
    }
    if (header && header.type && header.type === 'datetime') {
      if (header.field === 'dat_test_date') {
        return row[header.field];
      }

      if (header.field === 'dat_alcohol_test_time') {
        if (!row[header.field]) {
          return <> </>;
        }
        const time = Moment(row[header.field]).format('YYYY-MM-DD HH:mm:ss');
        return time;
      }
      if (row[header.field] !== null) {
        //var date = '2014-01-02T00:00:00.000Z'
        let date = row[header.field].toString();

        const hour = date.substring(11, 19).split(':');

        date = date.substring(0, 10).split('-');

        const dateAux = `${date[0]}-${date[1]}-${date[2]}`;

        return `${Moment(dateAux).format('DD-MMM-YYYY')} ${hour[0]}:${hour[1]
          }:${hour[2]}`;
      } else {
        return '';
      }
    }
    if (header && header.type && header.type === 'datemonth') {
      if (row[header.field] !== undefined) {
        const date = row[header.field];
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${t(`month_${month - 1}`).substring(0, 3)}-${year}`;
      } else {
        return '';
      }
    }
    if (header && header.type && header.type === 'date') {
      if (row[header.field] !== null) {
        //var date = '2014-01-02T00:00:00.000Z'
        let date = row[header.field].toString();

        date = date.substring(0, 10).split('-');

        const dateAux = `${date[0]}-${date[1]}-${date[2]}`;

        return `${Moment(dateAux).format('DD-MMM-YYYY')}`;
      } else {
        return '';
      }
    }
    if (header && header.type && header.type === 'image') {
      return (
        <CardImage
          name="image"
          src={row[header.field]}
          margin="6px 0px"
          size={60}
          disableDownload
        />
      );
    }
    if (header && header.type && header.type === 'key_taster') {
      return row[header.field] ? <div>
        <Key
          className={genericTableStyle.keyIcon}
          size={24}
        />
      </div> : null
    }
    if (header && header.type && header.type === 'email_response') {
      return <div>
        <img
          className={genericTableStyle.emailResponse}
          src={
            row[header.field] == 'accepted' ?
              DuiHao : row[header.field] == 'declined' ?
                ChaHao : WenHao
          }
        />
      </div>
    }
    if (header && header.field && header.field === 'str_remark') {
      return (
        <>
          <p
            data-tip={row.str_remark}
            data-for={`str_remark_${index}`}
            className={genericTableStyle.strRemark}
          >
            {row.str_remark}
          </p>
          <ReactTooltip
            id={`str_remark_${index}`}
            effect="solid"
            place="top"
            type="warning"
            className={genericTableStyle.strRemarkTooltip}
          />
        </>
      );
    }
    return header.shouldTranslate ? t(row[header.field]) : row[header.field];
  };

  const delayedQuery = useCallback(
    _.debounce(
      (page, rows, fields) => handleDataServer(page, rows, fields),
      750,
    ),
    [],
  );

  const query = useCallback(
    (page, rows, fields) => handleDataServer(page, rows, fields),
    [],
  );

  const filter = fromCheckbox => {
    if (fromCheckbox) {
      setIntPage(1);
      query(1, intRows, filters);
      return;
    }
    if (props.dataServer) {
      setIntPage(1);
      delayedQuery(1, intRows, filters);
      return;
    }

    setLoading(true);
    const emptyFilters = [];
    props.arrHeader.forEach(header => {
      emptyFilters.push(!filters[header.field]);
    });

    if (!emptyFilters.some(emptyFilter => emptyFilter === false)) {
      setFilteredRows(props.arrRow);
      setIntTotalRows(props.arrRow.length);
      setLoading(false);
      return;
    }
    const filteredValues = props.arrRow.filter(row => {
      let include = true;
      let date = '';
      props.arrHeader.forEach(header => {
        if (filters[header.field] && filters[header.field].trim().length > 0) {
          switch (header.type) {
            case 'datemonth':
              date = row[header.field];
              const month = date?.substring(5, 7);
              const year = date?.substring(0, 4);
              include &= `${t(`month_${month - 1}`)?.substring(0, 3)}-${year}`
                .toString()
                .trim()
                .toLowerCase()
                .includes(
                  filters[header.field].toString().trim().toLowerCase(),
                );
              break;
            case 'datetime':
              date = new Date(Date.parse(row[header.field]));
              const hours = date.getHours();
              const minutes = date.getMinutes();
              include &= `${date.getDate()}-${t(
                `month_${date.getMonth()}`,
              ).substring(0, 3)}-${date.getFullYear()} ${hours < 10 ? '0' + hours : hours
                }:${minutes < 10 ? '0' + minutes : minutes}`
                .toString()
                .toLocaleLowerCase()
                .includes(
                  filters[header.field].toString().trim().toLowerCase(),
                );
              break;
            case 'multi-action':
              include &= JSON.stringify(row[header.field])
                ?.toString()
                .trim()
                .toLowerCase()
                .includes(
                  filters[header.field].toString().trim().toLowerCase(),
                );
              break;
            case 'template_hidden':
              include &= JSON.stringify(row[header.is_true])
                ?.toString()
                .trim()
                .toLowerCase()
                .includes(
                  filters[header.field].toString().trim().toLowerCase(),
                );
              break;
            default:
              include &= row[header.field]
                ?.toString()
                .trim()
                .toLowerCase()
                .includes(
                  filters[header.field].toString().trim().toLowerCase(),
                );
              break;
          }
        }

        if (filters[header.field] && filters[header.field] === ' ') {
          include &=
            !row[header.field] ||
            typeof row[header.field] === undefined ||
            row[header.field] === null;
        }
      });
      return include;
    });

    if (filteredValues.length < intRows) {
      goToPage(1);
    }

    setFilteredRows(filteredValues);
    setIntTotalRows(filteredValues.length);

    setLoading(false);
  };

  const onCheckAll = evt => {
    setCheckAll(evt?.target?.value);
    if (props.dataServer) {
      for (let index = 0; index < filteredRows.length; index++) {
        if (props.onOKNOkCheck) {
          props.onOKNOkCheck(
            filteredRows[index],
            {
              target: {
                name: 'bol_check',
                value: evt?.target?.value,
              }
            }
          );
          props.onOKNOkCheck(
            filteredRows[index],
            {
              target: {
                name: 'sample_sum',
                value: evt?.target?.value ? (filteredRows[index]['sample_sum'] || 1) : 0,
              }
            }
          );
        } else {
          props.onCheck(filteredRows[index], evt);
        }
      }
    } else {
      for (let index = 0; index < filteredRows.length; index++) {
        if (index >= intPage * intRows - intRows && index < intPage * intRows) {
          if (props.onOKNOkCheck) {
            props.onOKNOkCheck(
              filteredRows[index],
              {
                target: {
                  name: 'bol_check',
                  value: evt?.target?.value,
                }
              }
            );
            props.onOKNOkCheck(
              filteredRows[index],
              {
                target: {
                  name: 'sample_sum',
                  value: evt?.target?.value ? (filteredRows[index]['sample_sum'] || 1) : 0,
                }
              }
            );
          } else {
            props.onCheck(filteredRows[index], evt);
          }
        }
      }
    }
  };

  const renderHeaderFirstColumn = () => {
    return (
      (props.onCheck || props.onOKNOkCheck) &&
      (
        <th style={{ textAlign: 'center' }}>
          {props.onCheck && !props.isShowRadio && (
            <Checkbox
              margin="0px 0px 0px -6px"
              onChange={evt => {
                onCheckAll(evt);
              }}
              value={checkAll}
            />
          )}
          {props.onOKNOkCheck && (
            <div
              id='all_add_once'
              className={genericTableStyle.allButton}
              onClick={() => {
                onCheckAll({
                  target: {
                    value: !checkAll
                  }
                })
              }}>
              {
                !checkAll
                  ? <img
                    src={SelectAll}
                    width='24px'
                    data-tip={t('select_all')}
                    data-for='select_all'
                  />
                  : <img
                    src={SelectAllChanged}
                    width='24px'
                    data-tip={t('select_all')}
                    data-for='select_all'
                  />
              }
              <ReactTooltip
                id='select_all'
                backgroundColor='#F0AD4E'
                effect="solid"
                place="right"
                multiline={false}
              />
            </div>
          )}
        </th>
      )
    )
  }

  const renderHeaders = () => {
    if (props.arrHeader) {
      return (
        <thead>
          <tr>
            {props.onCheck || props.onOKNOkCheck ? (
              <th width="65px" className={genericTableStyle.addBtn}>
                <Button
                  disabled={props.bol_splitRound}
                  round
                  confirm
                  onClick={() => {
                    setCheckAll(false);
                    props.handleAddSample();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  dataTip={t('add_samples')}
                  dataFor='add_samples'
                >
                  <Plus width={22} height={22} />
                </Button>
                <ReactTooltip
                  id='add_samples'
                  backgroundColor='#F0AD4E'
                  effect="solid"
                  place="right"
                  multiline={false}
                />
              </th>
            ) : null}
            {renderHeader(props.arrHeader)}
            {props.onRemove ||
              props.onEdit ||
              props.onDetail ||
              props.onInput ? (
              <th colSpan={3} style={{ textAlign: 'center' }}>
                {t('common_action')}
              </th>
            ) : null}
          </tr>
          {props.searchable ? (
            <tr>
              {renderHeaderFirstColumn()}
              {renderHeaderSearch(props.arrHeader)}
              {props.onRemove ? <th></th> : null}
              {props.onCopy ? <th></th> : null}
              {props.onEdit ? <th></th> : null}
              {props.onDetail ? <th></th> : null}
              {props.onInput ? <th></th> : null}
              {props.onView ? <th></th> : null}
            </tr>
          ) : null}
        </thead>
      );
    }
    return null;
  };

  const renderRows = () => {
    if (loading || props.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={20}>
              <Spinner />
            </td>
          </tr>
        </tbody>
      );
    }
    if (filteredRows) {
      return (
        <tbody className={genericTableStyle.tbody}>
          {renderRow(filteredRows, props.arrHeader)}
        </tbody>
      );
    }
    return <span>{t('common_no_data')}</span>;
  };

  const renderHeader = headers =>
    headers.map((header, index) => (
      <th
        className={genericTableStyle.description}
        key={index}
        style={props.headerStyle ? props.headerStyle : null}
      >
        {header.type === 'button' ? null : header.description}
      </th>
    ));

  const renderRemoveColumn = row => {
    if (props.onRemove) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span
            className={genericTableStyle.removeCell}
            onClick={() => {
              row.arr_tasted
                ? alert(
                  'You can not delete this sample as it has been selected or tasted in a round!',
                )
                : handleRemove(row);
            }}
          >
            {row.bol_send ?
              <img src={Download} style={{ width: '30px', height: '26px' }} />
              : row.bol_operate !== false && <Trash />
            }
          </span>
        </td>
      );
    }
    return null;
  };

  const handleRemove = row => {
    if (props.dataServer) {
      props.onRemove(row).then(res => {
        handleDataServer();
      });
    } else {
      props.onRemove(row);
    }
  };

  const handleAction = (header, row) => {
    if (props.dataServer && header.async === true) {
      header.action(row).then(res => {
        handleDataServer();
      });
    } else {
      header.action(row);
    }
  };

  const handleInput = row => {
    props.handleInput(row);
  };

  const renderEditColumn = row => {
    if (props.onEditPermission && !props.onEditPermission(row)) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span className={genericTableStyle.editCellBlock}>
            <Edit2 />
          </span>
        </td>
      );
    }

    if (props.onEdit) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span
            className={genericTableStyle.editCell}
            onClick={props.onEdit(row, props.onList)}
          >
            {props.onList === 0 ? (
              <Search />
            ) : row.bol_send ? (
              <img src={View} style={{ width: '30px' }} />
            ) : (
              <Edit2 />
            )}
          </span>
        </td>
      );
    }

    if (props.onInput) {
      if (
        new Date(row.dat_test_date).getTime() ===
        new Date(Moment(new Date()).format('YYYY-MM-DD')).getTime()
      ) {
        return (
          <td
            className={
              row.flt_alcohol_result === null || !props.alcholStandard
                ? ''
                : props.alcholStandard <= row.flt_alcohol_result
                  ? genericTableStyle.alcoholPk
                  : genericTableStyle.alcoholGr
            }
          >
            <div
              className={genericTableStyle.inputCell}
              onClick={() => {
                handleInput(row);
              }}
            >
              <Edit2 />
            </div>
          </td>
        );
      } else {
        return (
          <td
            className={
              row.flt_alcohol_result === null || !props.alcholStandard
                ? ''
                : props.alcholStandard <= row.flt_alcohol_result
                  ? genericTableStyle.alcoholPk
                  : genericTableStyle.alcoholGr
            }
          ></td>
        );
      }
    }
  };

  const renderDetailColumn = row => {
    if (props.onDetail) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span
            className={genericTableStyle.editCell}
            onClick={props.onDetail(row)}
          >
            <List />
          </span>
        </td>
      );
    }
    return null;
  };
  const renderCopyColumn = row => {
    if (props.onCopy) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span
            className={genericTableStyle.copyCell}
            onClick={() => {
              props.onCopy(row);
            }}
          >
            <Copy />
          </span>
        </td>
      );
    }
    return null;
  };

  const renderViewColumn = row => {
    if (props.onCopy) {
      return (
        <td style={{ textAlign: 'center' }}>
          <span
            className={genericTableStyle.copyCell}
            onClick={() => {
              props.onView(row);
            }}
          >
            <img src={View} style={{ width: '30px' }} />
          </span>
        </td>
      );
    }
    return null;
  };

  const handleCheckColumn = (row, evt) => {
    if (props.onOKNOkCheck) {
      props.onOKNOkCheck(row, evt)
    } else {
      props.onCheck(row, evt);
    }
    if (!evt?.target?.value && evt.target.name === 'bol_check') {
      setCheckAll(false);
    } else if (
      props.dataServer &&
      filteredRows?.length &&
      !filteredRows.some(obj => !obj.bol_check)
    ) {
      setCheckAll(true);
    } else if (
      !props.dataServer &&
      filteredRows?.length &&
      !filteredRows.some(
        (obj, index) =>
          index >= intPage * intRows - intRows &&
          index < intPage * intRows &&
          (!obj.bol_check && !obj.sample_sum),
      )
    ) {
      setCheckAll(true);
    }
  };

  const renderCheckColumn = row => {
    if (props.onCheck || props.onOKNOkCheck) {
      return (
        <td style={{
          textAlign: 'center',
          width: '65px'
        }}>
          {!props.onOKNOkCheck && <Checkbox
            name="bol_check"
            value={row.bol_check}
            margin="0px 0px 0px -6px"
            isRadio={props.isShowRadio}
            onChange={evt => {
              handleCheckColumn(row, evt);
            }}
          />}
          {props.onOKNOkCheck &&
            <div
              className={genericTableStyle.register}
              id='multiple_selection'
            >
              <Minus
                color='#F49B00'
                className={genericTableStyle.minus}
                onClick={() => {
                  const evt = {
                    target: {
                      name: 'sample_sum',
                      value: Number(row.sample_sum ?? 0) === 0 ? row.sample_sum : Number(row.sample_sum ?? 0) - 1,
                    }
                  };
                  if (Number(row.sample_sum ?? 0) !== 0) {
                    handleCheckColumn(row, evt);
                  };
                  handleCheckColumn(row, {
                    target: {
                      name: 'bol_check',
                      value: Number(row.sample_sum ?? 0) !== 0,
                    }
                  });
                }}
              />
              <TextField
                name='sample_sum'
                number
                value={row.sample_sum}
                onChange={evt => {
                  const regex = /^[1-9]\d*$/;
                  const inputValue = evt.target.value;
                  if ((regex.test(inputValue) && !inputValue.includes('.')) || inputValue === '') {
                    handleCheckColumn(row, evt);
                    handleCheckColumn(row, {
                      target: {
                        name: 'bol_check',
                        value: Number(inputValue ?? 0) !== 0,
                      }
                    });
                  }
                }}
                defaultValue={0}
                style={{
                  width: '46px',
                  height: '30px',
                  margin: '0',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
                flex='0'
              />
              <Plus
                color='#F49B00'
                className={genericTableStyle.plus}
                onClick={() => {
                  const evt = {
                    target: {
                      name: 'sample_sum',
                      value: Number(row.sample_sum ?? 0) + 1,
                    }
                  }
                  handleCheckColumn(row, evt);
                  handleCheckColumn(row, {
                    target: {
                      name: 'bol_check',
                      value: true,
                    }
                  });
                }}
              />
            </div>
          }
        </td>
      );
    }
    return null;
  };

  const renderHeaderSearch = headers =>
    headers.map((header, index) => {
      return (
        <th key={index} style={props.headerStyle ? props.headerStyle : null}>
          {header.haveDefaultDay ? (
            <div className={genericTableStyle.dateBox}>
              <DatePickerField
                alcohol
                name="dat_start"
                className={genericTableStyle.dateItem}
                type={'date'}
                value={tasteDate}
                format="dd-mm-yyyy"
                maxDate={Moment(new Date()).format('YYYY-MM-DD')}
                onChange={event => {
                  setTasteDate(event.target.value);
                  props.changeInputDate && props.changeInputDate(event);
                  event.persist();
                }}
              />
            </div>
          ) : (
            ''
          )}
          {header.selectSearch ? (
            <DropdownField
              headerSearch
              margin="0"
              options={header.changeOpts ?? []}
              valueField={header.valueField ?? 'value'}
              labelField={header.labelField ?? 'label'}
              bolOrder={false}
              hideArrow={true}
              padding=""
              value={searchInputValue[header.fieldCache]}
              onChange={event => {
                setLoading(true);
                const filtersTemp = filters;
                filtersTemp[header.fieldToSearch || header.field] =
                  typeof event.target.value == 'boolean' || event.target.value?.trim().length > 0
                    ? event.target.value
                    : null;
                if (header.cache === true && header.fieldCache) {
                  handleSaveStorage(
                    header.fieldCache,
                    typeof event.target.value == 'boolean' || event.target.value.trim().length > 0
                      ? event.target.value
                      : null,
                  );
                }

                setFilters(filtersTemp);
                filter();
                setSearchInputValue({
                  ...searchInputValue,
                  [header.fieldCache]: event.target.value,
                });
              }}
            />
          ) : null}
          {header.selectReceived ? (
            <DropdownField
              headerSearch
              margin="0"
              options={receivedOpts}
              valueField="label"
              labelField="value"
              bolOrder={false}
              hideArrow={true}
              padding="0"
              value={searchInputValue[header.fieldCache]}
              onChange={event => {
                setLoading(true);
                const filtersTemp = filters;
                filtersTemp[header.fieldCache] =
                  event.target.value?.trim().length > 0
                    ? event.target.value
                    : null;
                setFilters(filtersTemp);
                filter();
                setSearchInputValue({
                  ...searchInputValue,
                  [header.fieldCache]: event.target.value,
                });
              }}
            />
          ) : null}
          {header.selectKpiMonth ? (
            <DatePickerField
              type={'month'}
              margin="-8px 0px 0px 0px"
              minDate={
                !filters.show_all_samples
                  ? Moment(new Date()).subtract(6, 'month').format('YYYY-MM')
                  : ''
              }
              maxDate={Moment(new Date()).format('YYYY-MM')}
              height="32px"
              value={searchInputValue[header.fieldCache]}
              onChange={event => {
                setLoading(true);
                const filtersTemp = filters;
                filtersTemp[header.fieldToSearch || header.field] =
                  event.target.value.trim().length > 0
                    ? event.target.value
                    : null;
                setFilters(filtersTemp);
                filter();
                setSearchInputValue({
                  ...searchInputValue,
                  [header.fieldCache]: event.target.value,
                });
              }}
            />
          ) : null}
          {header.type !== 'button' && !header.notSearchable ? (
            <div
              className={genericTableStyle.searchFieldContainer}
              style={{ width: header.width }}
            >
              <Search className={genericTableStyle.searchIcon} size={20} />
              <input
                name="headerSearch"
                className={genericTableStyle.searchField}
                onChange={event => {
                  setLoading(true);
                  const filtersTemp = filters;
                  filtersTemp[header.fieldToSearch || header.field] =
                    event.target.value.trim().length > 0
                      ? event.target.value
                      : null;
                  if (header.cache === true && header.fieldCache) {
                    handleSaveStorage(
                      header.fieldCache,
                      event.target.value.trim().length > 0
                        ? event.target.value
                        : null,
                    );
                  }

                  setFilters(filtersTemp);
                  filter();
                  setSearchInputValue({
                    ...searchInputValue,
                    [header.fieldCache]: event.target.value,
                  });
                }}
                defaultValue={handleGetStorage(header.fieldCache) || ''}
                value={
                  header.cache === true && loading === false
                    ? searchInputValue[header.fieldCache]
                    : null
                }
              />
            </div>
          ) : null}
        </th>
      );
    });
  const handleSetIntRows = Rows => {
    if (props.rowsPage != 6) {
      const Data = {
        int_row_page: Rows,
      };
      userService.updateUserRowPage(Data).then(
        res => {
          if (res.type === 'success' && res.results) {
            let Avg = Math.ceil(intTotalRows / Rows);
            setIntRows(Rows);
            if (Avg < intPage && intTotalRows != 0) {
              setIntPage(Avg);
            }
          } else {
            window.alert(res.message);
          }
        },
        error => { },
      );
    } else {
      let Avg = Math.ceil(intTotalRows / Rows);
      setIntRows(Rows);
      if (Avg < intPage && intTotalRows != 0) {
        setIntPage(Avg);
      }
    }
  };

  const renderRow = (rows, headers) => {
    return rows?.map((row, index) => {
      if (props.dataServer) {
        return (
          <tr key={index}>
            {renderCheckColumn(row)}
            {
              headers?.map((header, indexHeader) => {
                if (header.field === 'bol_abi_account') {
                  return (
                    <td
                      key={indexHeader}
                    >
                      {row.bol_abi_account == false ? t('temporary') : ""}
                    </td>
                  );
                }
                return <td key={indexHeader}>{renderCellContent(header, row, index)}</td>

              })
            }
            {renderCopyColumn(row)}
            {renderEditColumn(row)}
            {renderViewColumn(row)}
            {renderRemoveColumn(row)}
            {renderDetailColumn(row)}
          </tr>
        );
      }

      if (index >= intPage * intRows - intRows && index < intPage * intRows) {
        if (props.onInput) {
          return (
            <tr key={index} className={genericTableStyle.trH50}>
              {renderCheckColumn(row)}
              {headers.map((header, indexHeader) => {
                if (header.field === 'flt_alcohol_result') {
                  return (
                    <td
                      key={indexHeader}
                      className={
                        row.flt_alcohol_result === null || !props.alcholStandard
                          ? genericTableStyle.alcoholBold
                          : props.alcholStandard <= row.flt_alcohol_result
                            ? genericTableStyle.alcoholPkB
                            : genericTableStyle.alcoholGrB
                      }
                    >
                      {renderCellContent(header, row, index)}
                    </td>
                  );
                }
                if (header.field === 'str_input_type') {
                  return (
                    <td
                      key={indexHeader}
                      className={
                        row.flt_alcohol_result === null || !props.alcholStandard
                          ? ''
                          : props.alcholStandard <= row.flt_alcohol_result
                            ? genericTableStyle.alcoholPk
                            : genericTableStyle.alcoholGr
                      }
                    >
                      {row.str_input_type ? t(`${row.str_input_type}`) : ''}
                    </td>
                  );
                }
                return (
                  <td
                    key={indexHeader}
                    className={
                      row.flt_alcohol_result === null || !props.alcholStandard
                        ? ''
                        : props.alcholStandard <= row.flt_alcohol_result
                          ? genericTableStyle.alcoholPk
                          : genericTableStyle.alcoholGr
                    }
                  >
                    {renderCellContent(header, row, index)}
                  </td>
                );
              })}

              {renderEditColumn(row)}
              {renderRemoveColumn(row)}
              {renderDetailColumn(row)}
            </tr>
          );
        }
        return (
          <tr key={index}>
            {renderCheckColumn(row)}
            {headers.map((header, indexHeader) => {
              return (
                <td key={indexHeader}>
                  {renderCellContent(header, row, index)}
                </td>
              );
            })}

            {renderEditColumn(row)}
            {renderRemoveColumn(row)}
            {renderDetailColumn(row)}
          </tr>
        );
      }
      return null;
    });
  };

  const renderNewRegisterButton = () => {
    if (props.newRegister) {
      return (
        <div style={{ textAlign: 'right' }}>
          <Button
            zIndex={0}
            confirm
            label={props.newRegister.label}
            onClick={props.newRegister.onClick}
            style={{
              whiteSpace: 'nowrap',
              width: 'auto',
              minWidth: '120px',
              padding: '10px',
            }}
          />
        </div>
      );
    }
    return <div style={{ height: '12px' }}></div>;
  };
  const renderExport = () => {
    if (props.exportLabel) {
      return (
        <div style={{ textAlign: 'right', marginRight: 10 }}>
          <Button
            confirm
            label={props.exportLabel}
            onClick={handleExportReport}
            style={{
              whiteSpace: 'nowrap',
              width: 'auto',
              minWidth: '120px',
              padding: '10px',
            }}
          />
        </div>
      );
    }
    return <div style={{ height: '12px' }}></div>;
  };

  const renderButtonList = (buttonList) => {
    if (buttonList) {
      return (
        <div style={{ textAlign: 'right' }}>
          {buttonList.map(button => {
            return (
              <>
                <Button
                  zIndex={0}
                  confirm
                  style={{
                    whiteSpace: 'nowrap',
                    width: 'auto',
                    minWidth: '120px',
                    padding: '10px',
                    ...button.style ?? {},
                    margin: '0px 5px 0px 0px'
                  }}
                  label={button.label}
                  onClick={button.onClick}
                  blue={button.blue}
                  dataTip={button.dataTip ?? null}
                  Ids={button.id ?? ''}
                />
                {button.dataTip &&
                  <ReactTooltip
                    id={button.dataFor ?? null}
                    effect="solid"
                    place="bottom"
                    type="warning"
                    style={{ whiteSpace: 'normal' }}
                  />
                }
              </>
            );
          })}
        </div>
      );
    }
  };

  const renderCheckboxFilter = () => {
    if (
      props.checkboxFilter &&
      props.checkboxFilter.fieldName &&
      props.checkboxFilter.label
    ) {
      return (
        <CardRow transparent itemAlign="center">
          <div
            style={
              props.checkboxFilter.minWidth
                ? {
                  minWidth: props.checkboxFilter.minWidth,
                  whiteSpace: 'nowrap',
                }
                : { whiteSpace: 'nowrap' }
            }
          >
            <Checkbox
              margin="10px 0px 0px 0px"
              padding="8px 0px 8px 20px"
              onChange={event => {
                const filtersTemp = filters;
                filtersTemp[props.checkboxFilter.fieldName] =
                  event.target.value === 1;
                setFilters(filtersTemp);
                filter(true);
              }}
              label={props.checkboxFilter.label}
              value={filters[props.checkboxFilter.fieldName]}
            />
          </div>
          {props.checkboxFilter.helpCircle ? (
            <div>
              <HelpCircle
                data-tip={props.checkboxFilter.helpCircle}
                style={{ color: '#B11F24', marginTop: 3, marginLeft: 20 }}
                size="20"
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                style={{ whiteSpace: 'normal' }}
              />
            </div>
          ) : (
            <></>
          )}
        </CardRow>
      );
    } else {
      return false;
    }
  };
  const renderRoundSampleColumn = () => {
    if (props.setShowRoundSamples) {
      return (
        <CardRow className={genericTableStyle.column_optionSample}>
          <Button
            className={genericTableStyle.column_options}
            label={
              t('column_options') +
              (props.originHeader.length - props.arrHeader.length > 0
                ? ' (' +
                (props.originHeader.length - props.arrHeader.length) +
                ')'
                : '')
            }
            onClick={() => props.setShowRoundSamples(true)}
          >
            <img src={editPermission} className={genericTableStyle.img} />
          </Button>
        </CardRow>
      );
    }
  };

  return (
    <>
      <CardRow transparent justifyContent="space-between">
        <CardRow transparent>
          {renderCheckboxFilter()}
          {props.renderFilterDom && props.renderFilterDom()}
        </CardRow>
        <CardRow transparent>
          {renderButtonList(props.buttonList ?? [])}
          {renderExport()}
          {renderButtonList(props.buttonNewList ?? [])}
          {renderNewRegisterButton()}
          {renderRoundSampleColumn()}
        </CardRow>
      </CardRow>
      {
        (props.customRanges || props.setShowCustomColumns) &&
        <CardRow
          style={{
            background: 'rgba(0,0,0,0)',
            margin: '15px 0 5px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {props.customRanges && (
              <div className={genericTableStyle.rangeRangePickerRow}>
                <ConfigProvider
                  locale={i18n.language.toUpperCase() === 'ZH' ? zhCN : enUS}
                >
                  <RangePicker
                    status="warning"
                    value={selectedValue}
                    ranges={{
                      [t('today')]: [dayjs(moment()), dayjs(moment())],
                      ...customRanges,
                    }}
                    onChange={handleRangeChange}
                    allowClear={false}
                    inputReadOnly
                  />
                </ConfigProvider>
                {props.rangesHelpCircle && (
                  <div>
                    <HelpCircle
                      data-tip={props.rangesHelpCircle}
                      style={{ color: '#B11F24', marginTop: 3, marginLeft: 20 }}
                      size="20"
                    />
                    <ReactTooltip
                      effect="solid"
                      place="top"
                      type="warning"
                      style={{ whiteSpace: 'normal' }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {props.setShowCustomColumns && (
            <Button
              className={genericTableStyle.column_options}
              label={
                t('column_options') +
                (props.originHeader.length - props.arrHeader.length > 0
                  ? ' (' +
                  (props.originHeader.length - props.arrHeader.length) +
                  ')'
                  : '')
              }
              onClick={() => props.setShowCustomColumns(true)}
            >
              <img src={editPermission} className={genericTableStyle.img} />
            </Button>
          )}
        </CardRow>
      }


      <div
        className={
          props.needMinHeight
            ? genericTableStyle.tableOverflowXMinHeight
            : genericTableStyle.tableOverflowX
        }
      >
        <table id="genericTable" className={genericTableStyle.table}>
          {renderHeaders()}
          {renderRows()}
        </table>
      </div>
      <PaginationTable
        hidedropdownOuterList={props.hidedropdownOuterList}
        filter={filters}
        totalRows={intTotalRows}
        rowsPerPage={intRows}
        selectedPage={intPage}
        goToPage={goToPage}
        intRows={intRows}
        setRowsPerPage={value => {
          handleSetIntRows(value);
        }}
      />
    </>
  );
};

GenericTable.propTypes = {
  arrRow: PropTypes.array,
  arrHeader: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      field: PropTypes.string.isRequired,
      action: PropTypes.func,
      onMouseEnter: PropTypes.func,
      onMouseLeave: PropTypes.func,
      shouldTranslate: PropTypes.bool,
      type: PropTypes.string,
      cache: PropTypes.bool,
      fieldCache: PropTypes.string,
    }),
  ).isRequired,
  headerStyle: PropTypes.object,
  newRegister: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onEditPermission: PropTypes.func,
  onDetail: PropTypes.func,
  onCheck: PropTypes.func,
  onCheckAll: PropTypes.func,
  searchable: PropTypes.bool,
  loading: PropTypes.bool,
  rowsPage: PropTypes.number,
  buttonList: PropTypes.shape([
    {
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      dataTip: PropTypes.string,
      id: PropTypes.string,
      blue: PropTypes.bool,
    },
  ]),
  checkboxFilter: PropTypes.shape({
    fieldName: PropTypes.string,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    helpCircle: PropTypes.string,
  }),
  dataServer: PropTypes.func,
  customRanges: PropTypes.array,
  defaultRangesDays: PropTypes.number,
};

export default GenericTable;
