import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import Style from './ListNextWeekPlan.module.scss';
import { GenericTable, Label, Modal, Loading, DiyTab, Button, CardRow } from '../../../components';
import RoundService from '../../services/rounds/round.service';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';
import configs from '../../../configs';
import SessionContent from './sessionContent';
import moment from 'moment';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';

const ListNextWeekPlan = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [blockRoundPrivilege, setBlockRoundPrivilege] = useState([]);
  const [sampleEditPrivilege, setSampleEditPrivilege] = useState([])
  const [loading, setLoading] = useState(true);
  const roundService = new RoundService();
  const reportService = new ReportService();
  const appService = new AppService();
  const [checkResult, setCheckResult] = useState(false);
  const [tabs, setTabs] = useState([])

  const [changeRowConfirm, setChangeRowConfirm] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sentLoading, setSentLoading] = useState(false);
  const [selectIndex, setSelectIndex] = useState(1);
  const confirmResult = useRef();
  useEffect(() => {
    refreshState();
  }, []);

  const sentEmail = async (item) => {
    const selectIndex = confirmResult.current.selectedTab
    const saveRes = await saveConfirmResult(item);
    if (saveRes && window.confirm(t("are_you_confirming_to_send_the_confirmation_email") + " ?")) {
      roundService.emailToConfirm({ ...item, dat_send_confirm: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
      setTimeout(() => {
        roundService.getPlanUser(item.uuid_next_week_round).then(res => {
          if (res.type === 'success' && res.results) {
            setChangeRowConfirm(res.results);
            setSelectIndex(selectIndex);
          } else {
            window.alert(res.message);
          }
        })
      }, 1000);
    }
  }

  const saveConfirmResult = (item) => {
    return new Promise((resolve) => {
      roundService.upUserConfirm(item).then(res => {
        resolve(res.type === 'success')
      })
    })
  }

  const renderTabContent = (item) => {
    return <div className={Style.tabContent}>
      <div className={Style.sessionInfo}>
        <div className={Style.session}>
          Session {item.int_order_app + 1}
        </div>
        <div className={Style.infoItem} style={{ flex: 2 }}>
          <b>{t('session_name_email_subject')}</b>
          <p>{item.str_session_name}</p>
        </div>
        <div className={Style.infoItem}>
          <b>{t('location')}</b>
          <p>{item.str_location}</p>
        </div>
      </div>
      <div className={Style.tableContent}>
        <div className={Style.table}>
          <SessionContent arr_users={item.arr_users} key={uuid()} />
        </div>
      </div>
      <div className={Style.control}>
        <Button
          label={t('group_close_modal')}
          margin="10px 10px 0px 0px"
          neutral
          onClick={() => {
            setCheckResult(false)
            setSelectIndex(1);
          }}
          style={{
            borderRadius: '6px',
            width: '160px',
            justifySelf: 'flex-start',
          }}
        />
        <div>
          <Button
            label={t('common_save')}
            margin="10px 10px 0px 0px"
            confirm
            // disabled={sendEmailLoading}
            loading={saveLoading}
            onClick={() => {
              saveConfirmResult(item);
            }}
            style={{
              borderRadius: '6px',
              width: '160px',
              marginLeft: 'auto',
              marginRight: 0,
            }}
          />
          &emsp;
          <Button
            label={t('save_and_send_email')}
            margin="10px 10px 0px 20px"
            confirm
            blue
            // disabled={saveLoading}
            loading={sentLoading}
            onClick={e => {
              sentEmail(item);
            }}
            style={{
              borderRadius: '6px',
              width: '160px',
              marginLeft: 'auto',
              marginRight: 0,
            }}
          />
        </div>
      </div>
    </div>
  };


  const refreshState = async () => {
    setLoading(true);
    await appService.listPrivileges('SAMPLE_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setSampleEditPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
        //GET PRIVILEGES
        appService.listPrivileges('ROUND_MANAGEMENT_EDIT').then(
          res => {
            if (res.type === 'success' && res.results) {
              setPrivilege(res.results[0]);
              setLoading(false);
            } else {
              window.alert(res.message);
              setLoading(false);
            }
          },
          error => {
            //console.log(error);
            setLoading(false);
          },
        );

        appService.listPrivileges('ROUND_BLOCK_RESULTS').then(
          res => {
            if (res.type === 'success' && res.results) {
              setBlockRoundPrivilege(res.results[0]);
            } else {
              //console.log(res);
            }
          },
          error => {
            //console.log(error);
          },
        );
      }
    );
  };

  const handleNewRound = useCallback(() => {
    history.push({
      pathname: '/new_week_plan',
      state: {
        privilege
      }
    })

  }, [privilege]);

  const handleEditRound = useCallback(
    values => () => {
      history.push({
        pathname: '/edit_week_plan',
        state: {
          rows: values,
          privilege: privilege,
          sampleEditPrivilege: sampleEditPrivilege,
          isNewPlan: false,
          isView: values.bol_send,
        },
      });
    },
    [privilege],
  );

  const handleRemoveRound = values => {
    if (values.bol_send) {
      reportService.downloadNextWeekPlan(
        { uuid_next_week_round: values.uuid_next_week_round }
      ).then(res => {
        if (res.status === 'success' && res.file) {
          setLoading(false);
          let a = document.createElement('a');
          a.href = configs.file_api_url + '/v1/' + res.file;
          a.download = res.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          setLoading(false);
          window.alert(res.message);
        }
      })

    } else {
      return new Promise(async (resolve, reject) => {
        if (window.confirm(`${t('round_remove_plan')} ?`)) {
          roundService.deleteNextWeekRound(values.uuid_next_week_round).then(
            res => {
              if (res.type === 'success' && res.results) {
                resolve(true);
              } else {
                window.alert(res.message);
                resolve(true);
              }
            },
            error => {
              reject(error);
            }
          )
        }
      })
    }
  };

  const handleCheckResult = roundClicked => {
    roundService.getPlanUser(roundClicked.uuid_next_week_round).then(res => {
      if (res.type === 'success' && res.results) {
        setChangeRowConfirm(res.results);
        setCheckResult(true);
      } else {
        window.alert(res.message);
      }
    })
  };

  useEffect(() => {
    const templateTabs = [];
    for (let i = 0; i < changeRowConfirm.length; i++) {
      const item = changeRowConfirm[i]
      templateTabs.push({
        index: i + 1,
        description: <div className={Style.tabHeader}>
          <div
            className={Style.sum}
            style={
              item.bol_send ?
                { color: '#7ec94a', background: '#fff' } :
                { color: '#8E939B', background: '#fff' }
            } >{item.arr_users?.length ?? 0}</div>
          <div className={Style.time}>
            <p>{item.dat_session_date ?? ''}</p>
            <p>{item.str_time_interval ?? ''}</p>
          </div>
        </div>,
        component: () => (
          renderTabContent(item)
        ),
        backgroundColor: item.bol_send ? '#7ec94a' : '#8E939B',
        fontColor: '#fff',
      });
    }
    setTabs(templateTabs);
  }, [changeRowConfirm])

  const filterHeaders = [
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'round.str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
      cache: true,
      fieldCache: 'round.str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
      cache: true,
      fieldCache: 'round.str_site_name',
    },
    {
      description: t('common_start_date'),
      field: 'dat_week_start',
      type: 'date',
      cache: true,
      fieldCache: 'round.dat_week_start',
    },
    {
      description: t('common_finish_date'),
      field: 'dat_week_end',
      type: 'date',
      cache: true,
      fieldCache: 'round.dat_week_end',
    },
    {
      description: t('common_time_zone'),
      field: 'str_time_zone_name',
      // type: 'datetime',
      cache: true,
      fieldCache: 'round.str_time_zone_name',
    },
    {
      description: t('common_last_response_time'),
      field: 'dat_last_response_time',
      cache: true,
      type: 'datetime',
      fieldCache: 'round.dat_last_response_time',
    },
    {
      description: t('common_session'),
      field: 'int_session',
      notSearchable: true,
    },
    {
      description: t('calendar_confirm'),
      field: 'bol_send',
      type: 'button',
      action: handleCheckResult,
      ruleToShow: 'NOT_NULL',
    },
  ];
  return (
    <div className={Style.pageContainer}>
      <CardRow transparent style={{ fontWeight: 'bold' }} itemAlign='center'>
        <Label label={t('list_next_week_plan')} bold neutral />
        <HelpCircle
          data-tip={t('every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation')}
          style={{ color: '#B11F24', marginTop: 3, marginLeft: 10 }}
          size="20"
        />
        <ReactTooltip
          effect="solid"
          place="top"
          type="warning"
          style={{ whiteSpace: 'normal' }}
        />
      </CardRow>
      <GenericTable
        originHeader={filterHeaders}
        arrHeader={filterHeaders}
        dataServer={roundService.getNextWeekPlanPagination}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditRound : null}
        onRemove={privilege.bol_access ? handleRemoveRound : null}
        loading={loading}
        newRegister={
          privilege.bol_access
            ? {
              onClick: handleNewRound,
              label: t('new_week_plan'),
            }
            : null
        }
        headerStyle={{ minWidth: '60px' }}
      />

      <Modal
        className={Style.editUsers}
        show={checkResult}
        style={{ height: '100%' }}
      >
        <Loading loading={loading} />
        {!loading && (
          <div className={Style.editUsersContent}>
            <div className={Style.editUsersContentUsers}>
              <DiyTab
                key={uuid()}
                tabs={tabs}
                ref={confirmResult}
                selectedTabIndex={selectIndex}
                marginTop='0px'
                justifyContent="left"
              />
            </div>
            <div className={Style.editUsersContentConfirm}>
              Confirmed Count:
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
export default ListNextWeekPlan;

