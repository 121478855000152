import { useTranslation } from "react-i18next";
import { Button, CardRow, DatePickerField, DropdownField, Label, Modal, Text, TextField } from "../../../components";
import Style from './NewWeekPlan.module.scss'
import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CNIMG from '../../../assets/languagePic/CN.png';
import ENIMG from '../../../assets/languagePic/EN.png';
import PTIMG from '../../../assets/languagePic/PT.png';
import ESIMG from '../../../assets/languagePic/ES.jpg';
import NLIMG from '../../../assets/languagePic/NL.jpg';
import FRIMG from '../../../assets/languagePic/FR.jpg';
import DEIMG from '../../../assets/languagePic/DE.jpg';
import RUIMG from '../../../assets/languagePic/RU.jpg';
import UAIMG from '../../../assets/languagePic/UA.jpg';
import KRIMG from '../../../assets/languagePic/KR.jpg';
import ITIMG from '../../../assets/languagePic/IT.jpg';
import addUser from '../../../assets/addUser.png'
import addedUser from '../../../assets/addedUser.png'
import { X } from "react-feather";
import uuid from "react-uuid";
import EditRoundTasters from "../../Rounds/editRoundTasters/EditRoundTasters";
import { useUser } from "../../../contexts/User";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Moment from 'moment';
import RoundService from '../../services/rounds/round.service';

const NewWeekPlan = (props) => {
  const history = useHistory();
  const _props = props.location.state
  const { t } = useTranslation();
  const [uuid_site, setUuidSite] = useState(_props.rows?.uuid_site);
  const [uuid_country, seUuidCountry] = useState(_props.rows?.uuid_country);
  const [uuid_zone, setUuidZone] = useState(_props.rows?.uuid_zone);
  const [str_time_zone, setTimeZone] = useState(_props.rows?.str_time_zone ?? '0');
  const [timeZoneOps, setTimeZoneOps] = useState([]);
  const [lastResponseTime, setLastResponseTime] = useState();
  const [invitedWeek, setInvitedWeek] = useState();
  const { user } = useUser();

  const [str_language, setStrLanguage] = useState(
    _props.rows?.str_language || (user.str_language === 'zh' ? 'zh' : 'en')
  )
  const languageOpts = [
    {
      value: 'en',
      label: 'EN',
      imgUrl: ENIMG,
    },
    {
      value: 'zh',
      label: 'CN',
      imgUrl: CNIMG,
    },
    // {
    //   value: 'pt',
    //   label: 'PT',
    //   imgUrl: PTIMG,
    // },
    // {
    //   value: 'es',
    //   label: 'ES',
    //   imgUrl: ESIMG,
    // },
    // {
    //   value: 'nl',
    //   label: 'NL',
    //   imgUrl: NLIMG,
    // },
    // {
    //   value: 'fr',
    //   label: 'FR',
    //   imgUrl: FRIMG,
    // },
    // {
    //   value: 'de',
    //   label: 'DE',
    //   imgUrl: DEIMG,
    // },
    // {
    //   value: 'ru',
    //   label: 'RU',
    //   imgUrl: RUIMG,
    // },
    // {
    //   value: 'uk',
    //   label: 'UA',
    //   imgUrl: UAIMG,
    // },
    // {
    //   value: 'kr',
    //   label: 'KR',
    //   imgUrl: KRIMG,
    // },
    // {
    //   value: 'it',
    //   label: 'IT',
    //   imgUrl: ITIMG,
    // },
  ];
  const [plans, setPlans] = useState([]);
  const [checkEditUsers, setCheckEditUsers] = useState(false);
  const [nowSession, setNowSession] = useState({});
  const [canChangeMinDate, setCanChangeMinDate] = useState();
  const [canChangeMaxDate, setCanChangeMaxDate] = useState();
  const roundService = new RoundService();
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [strNameSite, setStrNameSite] = useState('');
  const [isView, setIsView] = useState(_props.isView ?? false);
  const [nowUuid, setNowUuid] = useState(_props.rows?.uuid_next_week_round || uuid())

  useEffect(() => {
    setUuidZone(_props.isNewPlan === false ? _props.rows.uuid_zone : user.uuid_zone);
    seUuidCountry(_props.isNewPlan === false ? _props.rows.uuid_country : user.uuid_country);
    setUuidSite(_props.isNewPlan === false ? _props.rows.uuid_site : user.uuid_site);
    const hasSaturday = _props.isNewPlan === false ? _props.rows.dat_week_end : new Date();
    const today = new Date(hasSaturday);
    const dayOfWeek = today.getDay();
    const nextWeekSaturday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 + (6 - dayOfWeek)).toISOString().split('T')[0];
    const value = dayjs(nextWeekSaturday, 'YYYY-MM-DD');
    setLastResponseTime(
      _props.isNewPlan === false ?
        Moment(
          _props.rows?.dat_last_response_time.substring(0, 19)
        ).format('YYYY-MM-DD[T]HH:mm:ss') : null
    )

    setInvitedWeek(value);
    setPlans(
      _props.isNewPlan === false ?
        _props.rows?.arr_sessions.map(item => {
          item.dat_start = Moment(item.dat_start).format('YYYY-MM-DD[T]HH:mm:ss');
          item.dat_end = Moment(item.dat_end).format('YYYY-MM-DD[T]HH:mm:ss');
          item.arr_users.forEach(user => {
            user.bol_taster = true
          })
          return item;
        }) :
        Array(5).fill().map(() => ({ uuid_next_week_round_session: uuid() }))
    )
  }, [])
  useEffect(() => {
    const startOfWeek = dayjs(invitedWeek).startOf('week').format('YYYY-MM-DD');
    const endOfWeek = dayjs(invitedWeek).endOf('week').format('YYYY-MM-DD');
    setCanChangeMinDate(startOfWeek);
    setCanChangeMaxDate(endOfWeek);
  }, [invitedWeek])

  useEffect(() => {
    if (_props.privilege.arr_sites) {
      const str_name = _props.privilege.arr_sites.find((item) => item.uuid_site === uuid_site)?.str_name
      setStrNameSite(str_name);
    }
  }, [uuid_site])

  useEffect(() => {
    getTimeZone()
  }, [uuid_zone])

  useEffect(() => {
    if (checkEditUsers === false) {
      setNowSession({});
    }
  }, [checkEditUsers])

  const addPlan = () => {
    const templatePlans = plans;
    templatePlans.push({ uuid_next_week_round_session: uuid() });
    setPlans([...templatePlans]);
  }

  const getTimeZone = () => {
    if (uuid_zone) {
      roundService.getTimeZone(uuid_zone).then(res => {
        if (res) {
          setTimeZoneOps(res.results.length ? res.results : []);
        }
      })
    }
  }

  const changeRemove = (changeIndex) => {
    const templatePlans = plans.filter((item, index) => index !== changeIndex);
    setPlans([...templatePlans]);
  }

  const handelAddUser = (data) => {
    setCheckEditUsers(true);
    setNowSession({ ...data });
  }

  const handelSaveSessionUser = () => {
    const templatePlans = plans.map((item, index) => {
      if (index === nowSession.index) {
        nowSession.arr_users = nowSession.arr_users.filter(item => item.bol_taster);
        item = nowSession
      }
      return item;
    })
    setPlans([...templatePlans]);
    setCheckEditUsers(false);
  }

  const handelChangePlan = (e, changeIndex) => {
    const name = e.target.name;
    const value = e.target.value;
    const templatePlans = plans.map((item, index) => {
      if (index === changeIndex) {
        const nowStartChange = name === 'dat_start' && item['dat_end'] && getTimes(value) >= getTimes(item['dat_end'])
        const nowEndChange = name === 'dat_end' && item['dat_start'] && getTimes(value) <= getTimes(item['dat_start'])
        if (nowStartChange || nowEndChange) {
          window.alert(
            `Session ${changeIndex + 1}: ` + t('the_end_time_is_greater_than_the_current_time'),
          );
        }
        item[name] = value;
      }
      return item;
    });
    setPlans(templatePlans);
  }

  const getTimes = (date) => {
    return new Date(date ?? null).getTime();
  }

  const SavePlans = (notBack = false, uuid) => {
    return new Promise(async (resolve, reject) => {
      if (!uuid_zone) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('common_zone')}` }),
        );
        return;
      }
      if (!uuid_country) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('common_country')}` }),
        );
        return;
      }
      if (!uuid_site) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('common_site')}` }),
        );
        return;
      }
      if (!str_time_zone) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('common_time_zone')}` }),
        );
        return;
      }
      if (!lastResponseTime) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('common_last_response_time')}` }),
        );
        return;
      }
      if (!str_language) {
        window.alert(
          t('common_cannot_be_empty', { field: `${t('email_language')}` }),
        );
        return;
      }
      for (let i = 0; i < plans.length; i++) {
        if (!plans[i].dat_start) {
          window.alert(
            t('common_cannot_be_empty', { field: `Session ${i + 1}: ${t('common_start_time')} ` }),
          );
          return;
        }
        if (!plans[i].dat_end) {
          window.alert(
            t('common_cannot_be_empty', { field: `Session ${i + 1}: ${t('common_end_time')} ` }),
          );
          return;
        }
        if (!plans[i].str_session_name) {
          window.alert(
            t('common_cannot_be_empty', { field: `Session ${i + 1}: ${t('session_name_email_subject')} ` }),
          );
          return;
        }
        if (!plans[i].str_location) {
          window.alert(
            t('common_cannot_be_empty', { field: `Session ${i + 1}: ${t('location')} ` }),
          );
          return;
        }
        if (!plans[i].arr_users || plans[i].arr_users.length === 0) {
          window.alert(
            t('common_cannot_be_empty', { field: `Session ${i + 1}: ${t('common_taster')} ` }),
          );
          return;
        }
        plans[i].int_order_app = i;
      }
      setSaveLoading(true);
      const data = {
        bol_edit: _props.isNewPlan === false ? true : false,
        uuid_next_week_round: nowUuid,
        uuid_zone,
        uuid_country,
        uuid_site,
        dat_week_start: canChangeMinDate,
        dat_week_end: canChangeMaxDate,
        str_time_zone,
        dat_last_response_time: lastResponseTime,
        str_language,
        str_name_site: strNameSite,
        str_time_zone_iana: timeZoneOps.find((item) => item.str_time_zone === str_time_zone)?.str_time_zone_iana,
        arr_sessions: plans
      }

      const res = await roundService.saveNextWeekPlan(data);
      if (res) {
        resolve(true);
        if (!notBack) {
          handleBack();
        } else {
          setEmailCheck(true);
        }

      } else {
        resolve(false);
      }
      setSaveLoading(false);
    })
  }

  const sentEmail = () => {
    setSendEmailLoading(true);
    const data = {
      bol_edit: _props.isNewPlan === false ? true : false,
      uuid_next_week_round: nowUuid,
      uuid_zone,
      uuid_country,
      uuid_site,
      dat_week_start: canChangeMinDate,
      dat_week_end: canChangeMaxDate,
      str_time_zone,
      dat_last_response_time: lastResponseTime,
      str_language,
      str_name_site: strNameSite,
      str_time_zone_iana: timeZoneOps.find((item) => item.str_time_zone === str_time_zone)?.str_time_zone_iana,
      arr_sessions: plans
    }
    roundService.nextWeekInvite(data)
    setSendEmailLoading(false);
    setTimeout(() => {
      handleBack();
    }, 500);
    setSendEmailLoading(true);
  }


  const handleBack = () => {
    history.replace({ pathname: '/list_next_week_plan' })
  }

  return (
    <div className={Style.pageContainer}>
      <Label
        label={
          _props.isNewPlan === false
            ? (_props.isView ? t('view_week_plan') : t('edit_week_plan'))
            : t('new_week_plan')
        }
        bold
        neutral
      />
      <div className={Style.Screen}>
        <CardRow flexWrap="wrap" transparent >
          <DropdownField
            name="uuid_zone"
            options={_props.privilege.arr_zones ? _props.privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            disabled={isView}
            value={uuid_zone}
            onChange={value => {
              seUuidCountry(null);
              setUuidSite(null);
              setUuidZone(value.target.value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="uuid_country"
            options={
              _props.privilege.arr_countries &&
                (_props.rows?.uuid_zone || uuid_zone !== undefined)
                ? _props.privilege.arr_countries.filter(country => {
                  const idZoneAux =
                    uuid_zone !== undefined ? uuid_zone : _props.rows?.uuid_zone;
                  return country.uuid_zone === idZoneAux;
                })
                : []
            }
            disabled={isView}
            valueField="uuid_country"
            labelField="str_name"
            label={`${t('common_country')} `}
            margin="10px 10px 0px 0px"
            value={uuid_country}
            onChange={value => {
              setUuidSite(null);
              seUuidCountry(value.target.value);
            }}
            isRequired={true}
          />

          <DropdownField
            name="uuid_site"
            options={
              _props.privilege.arr_sites &&
                (_props.rows?.uuid_country || uuid_country !== undefined)
                ? _props.privilege.arr_sites.filter(site => {
                  const idCountryAux =
                    uuid_country !== undefined
                      ? uuid_country
                      : _props.rows?.uuid_country;
                  return site.uuid_country === idCountryAux;
                })
                : []
            }
            disabled={isView}
            valueField="uuid_site"
            labelField="str_name"
            label={`${t('common_site')} `}
            margin="10px 10px 0px 0px"
            value={uuid_site}
            onChange={value => {
              const valueCountry =
                uuid_country !== undefined ? uuid_country : _props.rows?.uuid_country;

              const siteLength = _props.privilege.arr_sites.filter(site => {
                return (
                  site.uuid_country === valueCountry &&
                  value.target.value === site.uuid_site
                );
              });

              if (siteLength.length > 0) {
                setUuidSite(value.target.value);
              }
            }}
            isRequired={true}
          />
          <div className={Style.weekChange}>
            <Label
              label={
                t('invited_week')
              }
              required
            />
            <DatePicker
              className={Style.weekPicker}
              value={invitedWeek}
              disabled={isView}
              minDate={dayjs(new Date())}
              onChange={(date, dateString) => {
                setInvitedWeek(date);
              }}
              picker="week"
              allowClear={false}
            />
          </div>
          <DropdownField
            name="time_zone"
            options={
              timeZoneOps
            }
            disabled={isView}
            valueField="str_time_zone"
            labelField="str_time_zone_name"
            label={`${t('common_time_zone')} `}
            margin="10px 10px 0px 0px"
            value={str_time_zone}
            onChange={value => {
              setTimeZone(value.target.value);
            }}
            isRequired={true}
          />
          <DatePickerField
            name="last_response_time"
            type={'datetime-local'}
            label={t('common_last_response_time')}
            margin="10px 10px 0px 0px"
            value={lastResponseTime}
            disabled={isView}
            onChange={(e) => {
              setLastResponseTime(e.target.value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="str_language"
            options={languageOpts}
            valueField="value"
            labelField="label"
            label={t('email_language')}
            margin="10px 10px 0px 0px"
            value={str_language}
            disabled={isView}
            onChange={(e) => {
              setStrLanguage(e.target.value);
            }}
            bolOrder={false}
            isRequired
          />
        </CardRow>
        <div className={Style.days}>
          <div className={Style.plans}>
            {
              plans.map((item, index) => {
                return (
                  <div className={Style.planItem} key={index}>
                    <div className={Style.Session}>
                      Session {index + 1}
                    </div>
                    <div className={Style.planItemLeft}>
                      <DatePickerField
                        name="dat_start"
                        type={'datetime-local'}
                        label={t('common_start_time')}
                        margin="10px 10px 0px 0px"
                        disabled={isView}
                        value={item.dat_start}
                        onChange={(e) => {
                          handelChangePlan(e, index);
                        }}
                        isRequired={true}
                        minDate={canChangeMinDate + 'T00:00'}
                        maxDate={canChangeMaxDate + 'T23:59'}
                      />
                      <DatePickerField
                        name="dat_end"
                        type={'datetime-local'}
                        label={t('common_end_time')}
                        margin="10px 10px 0px 0px"
                        disabled={isView}
                        value={item.dat_end}
                        onChange={(e) => {
                          handelChangePlan(e, index);
                        }}
                        isRequired={true}
                        minDate={item.dat_start ?? canChangeMinDate + 'T00:00'}
                        maxDate={item.dat_start ?? canChangeMaxDate + 'T23:59'}
                      />
                      <TextField
                        name="str_session_name"
                        label={t('session_name_email_subject')}
                        margin="10px 10px 0px 0px"
                        value={
                          item.str_session_name
                        }
                        onChange={(e) => {
                          handelChangePlan(e, index);
                        }}
                        disabled={isView}
                        // maxLength="40"
                        isRequired={true}
                      />
                      <TextField
                        name="str_location"
                        label={t('location')}
                        margin="10px 10px 0px 0px"
                        value={
                          item.str_location
                        }
                        onChange={(e) => {
                          handelChangePlan(e, index);
                        }}
                        disabled={isView}
                        // maxLength="40"
                        isRequired={true}
                      />
                    </div>
                    <div className={Style.planItemRight}>
                      <div
                        className={Style.user}
                        onClick={() => {
                          handelAddUser({ ...item, index });
                        }}
                      >
                        <img width={30} src={item.arr_users?.length > 0 ? addedUser : addUser} />
                      </div>
                      {
                        !isView &&
                        <Button
                          round
                          remove
                          className={Style.removeButton}
                          onClick={evt => {
                            changeRemove(index);
                          }}
                        >
                          <X width='16px' />
                        </Button>
                      }
                    </div>
                  </div>
                )
              })
            }
            {
              !isView && <div className={Style.addCount} onClick={() => { addPlan() }} >
                <span className={Style.addCard}>+</span>
                Add a Session
              </div>
            }

          </div>
          <div className={Style.control}>
            <Button
              label={t('common_back')}
              margin="10px 10px 0px 0px"
              neutral
              onClick={() => {
                handleBack();
              }}
              style={{
                borderRadius: '6px',
                width: '160px',
                justifySelf: 'flex-start',
              }}
            />
            {
              !isView &&
              <div>
                <Button
                  label={t('common_save')}
                  margin="10px 10px 0px 0px"
                  confirm
                  disabled={sendEmailLoading}
                  loading={saveLoading}
                  onClick={() => {
                    SavePlans();
                  }}
                  style={{
                    borderRadius: '6px',
                    width: '160px',
                    marginLeft: 'auto',
                    marginRight: 0,
                  }}
                />
                &emsp;
                <Button
                  label={t('save_and_send_email')}
                  margin="10px 10px 0px 20px"
                  confirm
                  blue
                  disabled={saveLoading}
                  loading={sendEmailLoading}
                  onClick={() => {
                    SavePlans(true);
                  }}
                  style={{
                    borderRadius: '6px',
                    width: '160px',
                    marginLeft: 'auto',
                    marginRight: 0,
                  }}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        className={Style.editUsers}
        show={checkEditUsers}
        style={{ height: '100%', }}
      >
        <Button
          onClick={() => {
            setCheckEditUsers(false);
          }}
          className={Style.btnClose}
        >
          <X />
        </Button>
        <b style={{ fontSize: '16px' }}>{t('round_status_tasters')}</b>
        <EditRoundTasters
          disabled={isView}
          round={nowSession}
          setRound={(data) => {
            setNowSession({ ...nowSession, ...data })
          }}
          hideEmail
        />
        {!isView &&
          <Button
            label={t('common_save')}
            margin="10px 10px 0px 0px"
            confirm
            onClick={() => {
              handelSaveSessionUser();
            }}
            style={{
              borderRadius: '6px',
              width: '160px',
              float: 'right'
            }}
          />}
      </Modal>
      <Modal
        className={Style.Modal}
        show={emailCheck}
      >
        <CardRow className={Style.cardRowModal}>
          <Text className={Style.h3Title}>
            {t('are_you_confirming_to_send_the_invitation_email')} ?
          </Text>
        </CardRow>
        <CardRow
          className={[
            Style.buttonsWrapper,
            Style.cardRowModal,
          ].join(' ')}
        >
          <Button
            label={t('review_warning_sample_review')}
            remove
            onClick={() => setEmailCheck(false)}
          ></Button>
          <Button
            label={t('review_warning_sample_ok')}
            confirm
            onClick={() => {
              setEmailCheck(false)
              sentEmail();
            }}
          ></Button>
        </CardRow>

      </Modal>
    </div >
  )
}

export default NewWeekPlan;