let configs = {};
//console.log(process.env.REACT_APP_STAGE);
configs.defaultDebounce = 770;
configs.val_version = '2.7.2';
if (process.env.REACT_APP_STAGE === 'local') {
  configs.defaultUrl = 'http://localhost:3000';
  configs.socket_api_url = 'http://localhost:2121';
  configs.auth_api_url = 'http://localhost:2121';
  configs.file_api_url = 'http://localhost:2124';
  configs.user_api_url = 'http://localhost:2120';
  configs.sample_api_url = 'http://localhost:2118';
  configs.brand_api_url = 'http://localhost:2116';
  configs.package_api_url = 'http://localhost:2117';
  configs.round_api_url = 'http://localhost:2119';
  configs.group_api_url = 'http://localhost:2115';
  configs.report_api_url = 'http://localhost:2125';
  configs.malt_varieties_api_url = 'http://localhost:2122';
  configs.threshold_api_url = 'http://localhost:2123';
  configs.triangle_api_url = 'http://localhost:2127';
  configs.socket_servers_api_url = 'http://localhost:2126';
}
if (process.env.REACT_APP_STAGE === 'prod') {
  configs.defaultUrl = 'https://sensoryone.ab-inbev.com';
  configs.socket_api_url = 'https://ws-sensoryone.ab-inbev.com';
  configs.auth_api_url = 'https://api-sensoryone.ab-inbev.com/auth';
  configs.file_api_url = 'https://api-sensoryone.ab-inbev.com/file';
  configs.user_api_url = 'https://api-sensoryone.ab-inbev.com/user';
  configs.sample_api_url = 'https://api-sensoryone.ab-inbev.com/sample';
  configs.brand_api_url = 'https://api-sensoryone.ab-inbev.com/brand';
  configs.package_api_url = 'https://api-sensoryone.ab-inbev.com/package';
  configs.round_api_url = 'https://api-sensoryone.ab-inbev.com/round';
  configs.group_api_url = 'https://api-sensoryone.ab-inbev.com/group';
  configs.report_api_url = 'https://api-sensoryone.ab-inbev.com/report';
  configs.malt_varieties_api_url =
    'https://api-sensoryone.ab-inbev.com/malt_varieties';
  configs.threshold_api_url = 'https://api-sensoryone.ab-inbev.com/threshold';
  configs.triangle_api_url = 'https://api-sensoryone.ab-inbev.com/triangle';
  configs.socket_servers_api_url = 'https://api-sensoryone.ab-inbev.com';
}
if (process.env.REACT_APP_STAGE === 'qa') {
  configs.defaultUrl = 'https://sensoryone-qa.ab-inbev.com';
  configs.socket_api_url = 'https://ws-sensoryone-qa.ab-inbev.com';
  configs.auth_api_url = 'https://api-sensoryone-qa.ab-inbev.com/auth';
  configs.file_api_url = 'https://api-sensoryone-qa.ab-inbev.com/file';
  configs.user_api_url = 'https://api-sensoryone-qa.ab-inbev.com/user';
  configs.sample_api_url = 'https://api-sensoryone-qa.ab-inbev.com/sample';
  configs.brand_api_url = 'https://api-sensoryone-qa.ab-inbev.com/brand';
  configs.package_api_url = 'https://api-sensoryone-qa.ab-inbev.com/package';
  configs.round_api_url = 'https://api-sensoryone-qa.ab-inbev.com/round';
  configs.group_api_url = 'https://api-sensoryone-qa.ab-inbev.com/group';
  configs.report_api_url = 'https://api-sensoryone-qa.ab-inbev.com/report';
  configs.malt_varieties_api_url =
    'https://api-sensoryone-qa.ab-inbev.com/malt_varieties';
  configs.threshold_api_url =
    'https://api-sensoryone-qa.ab-inbev.com/threshold';
  configs.triangle_api_url = 'https://api-sensoryone-qa.ab-inbev.com/triangle';
  configs.socket_servers_api_url = 'https://api-sensoryone-qa.ab-inbev.com';
}
if (process.env.REACT_APP_STAGE === 'dev') {
  configs.defaultUrl = 'https://sensoryone-dev.ab-inbev.com';
  configs.socket_api_url = 'https://ws-sensoryone-dev.ab-inbev.com';
  configs.auth_api_url = 'https://api-sensoryone-dev.ab-inbev.com/auth';
  configs.file_api_url = 'https://api-sensoryone-dev.ab-inbev.com/file';
  configs.user_api_url = 'https://api-sensoryone-dev.ab-inbev.com/user';
  configs.sample_api_url = 'https://api-sensoryone-dev.ab-inbev.com/sample';
  configs.brand_api_url = 'https://api-sensoryone-dev.ab-inbev.com/brand';
  configs.package_api_url = 'https://api-sensoryone-dev.ab-inbev.com/package';
  configs.round_api_url = 'https://api-sensoryone-dev.ab-inbev.com/round';
  configs.group_api_url = 'https://api-sensoryone-dev.ab-inbev.com/group';
  configs.report_api_url = 'https://api-sensoryone-dev.ab-inbev.com/report';
  configs.malt_varieties_api_url =
    'https://api-sensoryone-dev.ab-inbev.com/malt_varieties';
  configs.threshold_api_url =
    'https://api-sensoryone-dev.ab-inbev.com/threshold';
  configs.triangle_api_url = 'https://api-sensoryone-dev.ab-inbev.com/triangle';
  configs.socket_servers_api_url = 'https://api-sensoryone-dev.ab-inbev.com';
}

export default configs;

