import { GenericTable } from "../../../components";
import DuiHao from '../../../assets/duihao.png'
import ChaHao from '../../../assets/chahao.png'
import WenHao from '../../../assets/wenhao.png'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import Style from './ListNextWeekPlan.module.scss';
import { Key } from "react-feather";
import ReactTooltip from "react-tooltip";

const SessionContent = (props) => {
  const { t } = useTranslation();
  const [arrUsers, setArrUsers] = useState(props.arr_users)
  // useEffect(() => {
  //   const obj = {
  //     "bol_abi_account": null,
  //     "bol_confirm": null,
  //     "bol_key_taster": 1,
  //     "str_abi_id": "CH0010",
  //     "str_email": "henry.gao@budweiserapac.com",
  //     "str_image": null,
  //     "str_name": "HeroLee",
  //     "str_response_status": "none",
  //   }
  //   const arr = Array(40).fill().map(() => (obj))
  //   setArrUsers(arr)
  // }, [])
  const handleToggleChange = (uuid_user) => {
    // const tabIndex = confirmResult.current.selectedTab - 1;
    const index = arrUsers.findIndex(item => item.uuid_user === uuid_user)
    arrUsers[index].bol_confirm = !arrUsers[index].bol_confirm
    setArrUsers([...arrUsers]);
    // confirmResult.current.setSelectedTab(tabIndex + 1)
  };
  const tagOpts = [
    {
      value: 'accepted',
      // imgUrl: DuiHao,
      label: 'Accepted'
    },
    {
      value: 'declined',
      // imgUrl: ChaHao,
      label: 'Declined'
    },
    {
      value: 'none',
      // imgUrl: WenHao,
      label: 'No Response'
    },
  ]
  const tabHeaders = [
    { description: t('common_name'), field: 'str_name' },
    { description: t('common_email'), field: 'str_email' },
    {
      description: t('key_tasters'),
      field: 'bol_key_taster',
      type: 'key_taster',
      fieldCache: 'bol_key_taster',
      notSearchable: true,
      selectSearch: true,
      changeOpts: [
        {
          value: "1",
          label: "Yes",
        },
        {
          value: "0",
          label: "No",
        },
      ],
    },
    {
      description: t('email_response'),
      field: 'str_response_status',
      fieldCache: 'str_response_status',
      type: 'email_response',
      notSearchable: true,
      selectSearch: true,
      changeOpts: tagOpts,
      width: '80px',
    },
    {
      description: t('confirmation'),
      field: 'bol_confirm',
      type: 'template_hidden',
      action: handleToggleChange,
      isConfirm: true,
      ruleToShow: 'TOGGLE',
      notSearchable: true,
    },
  ];

  const renderUser = (item, index) => {
    return <div
      key={index}
      className={Style.user}
      style={{
        borderColor:
          item.str_response_status === 'declined' ?
            '#D82D2C' :
            item.str_response_status === 'accepted' ?
              '#87c700' :
              '#ab5ab5'
      }}
    >
      <div className={Style.userInfo}>
        <p
          data-tip={item.str_name + '\n-\n' + item.str_email}
        >
          {item.str_name}
        </p>
        <ReactTooltip
          effect="solid"
          place="top"
          type="warning"
          className={Style.tooltip}
        />
        {/* <p>{item.str_email}</p> */}
      </div>
      <div>
        <input
          type="checkbox"
          checked={item.bol_confirm}
          onChange={() => {
            handleToggleChange(item.uuid_user)
          }}
          className={Style.checkConfirm}
        />
      </div>
    </div>
  }

  return <div>
    {console.log(arrUsers)}
    <div className={Style.tips}>
      {t('email_response')}:
      <p><span className={Style.lump}></span>Accepted</p>
      <p>
        <span
          className={Style.lump}
          style={{
            borderColor: '#c73e36'
          }}
        ></span>
        Declined
      </p>
      <p>
        <span
          className={Style.lump}
          style={{
            borderColor: '#ab5ab5'
          }}
        ></span>
        No Response
      </p>
    </div>
    {
      arrUsers.filter(item => item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('key_tasters')}</b>
          <div className={Style.userContent}>
            {
              arrUsers.filter(item => item.bol_key_taster)
                .map((item, index) => (
                  renderUser(item, index)
                ))
            }
          </div>
        </> : null
    }
    {
      arrUsers.filter(item => !item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('non_key_tasters')}</b>
          <div className={Style.userContent}>
            {
              arrUsers.filter(item => !item.bol_key_taster)
                .map((item, index) => (
                  renderUser(item, index)
                ))
            }
          </div>
        </> : null
    }
    {/* <GenericTable
      arrHeader={tabHeaders}
      arrRow={arrUsers}
      searchable={true}
      rowsPage={20}
      // onEdit={handleEditBrand}
      // onList={privilege.bol_access}
      // onRemove={privilege.bol_access ? handleRemoveBrand : null}
      // newRegister={privilege.bol_access ? {
      //   onClick: handleNewBrand,
      //   label: t('brand_new_brand')
      // } : null}
      // loading={loading}
      headerStyle={{ width: '100px' }}
      handleToggleChange={handleToggleChange}
    /> */}
  </div >
}

export default SessionContent;