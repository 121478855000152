import React, { useState, useEffect } from 'react';
import listReportsStyle from './listreports.module.scss';
import {
  Label,
  Card,
  CardRow,
  Button,
  CardColumn,
  DropdownField,
} from '../../../components';
import BrandService from '../../services/brands/brand.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/spinner/Spinner';
import configs from '../../../configs';
import _ from 'lodash';

const ListReportsKpi = props => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [brandsGlobal, setBrandsGlobal] = useState([]);
  const [kYear, setKYear] = useState([{ str_name: 2021, kpi_year: 2021 }]);
  const brandService = new BrandService();
  const reportService = new ReportService();

  useEffect(() => {
    setLoading(true);

    const yearNow = new Date().getFullYear();
    const timeInterval = yearNow - 2021;
    const year = [{ str_name: 2021, kpi_year: 2021 }];
    for (let i = 1; i <= timeInterval; i++) {
      year.push({ str_name: 2021 + i, kpi_year: 2021 + i });
    }
    setKYear(year);
    setLoading(false);

    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          const arrZonesGlobal = [];

          res.results.map(b => {
            return b.arr_zones?.map(z => {
              if (z.str_name === 'GLOBAL') {
                arrZonesGlobal.push(b);
                return z.str_name === 'GLOBAL';
              }
            });
          });

          setBrandsGlobal(arrZonesGlobal);
          setLoading(false);
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {
        //console.log(error);
        setLoading(false);
      },
    );
  }, []);

  const handleChange = evt => {
    const value = evt.target.value;
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const handleDownload = () => {
    if (!filter.kpi_year) {
      alert(t('select_year_to_filter'));
      return false;
    }

    if (!filter.uuid_brand) {
      alert(t('select_brand_to_filter'));
      return false;
    }

    if (
      filter.uuid_brand === '3b35b14-5c3b-bcae-d587-737b7c736f' ||
      filter.uuid_brand === '16bcaaa-2f83-34a4-675f-ff005234ba70'
    ) {
      setButtonLoading(true);
      reportService
        .generateKpiBrandReport(filter.uuid_brand, filter.kpi_year)
        .then(
          res => {
            if (res.status === 'success' && res.file) {
              let a = document.createElement('a');
              a.setAttribute('target', '_blank');
              a.href = configs.file_api_url + '/v1/' + res.file;
              a.download = res.file;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              window.alert(res.message);
            }
            setButtonLoading(false);
          },
          error => {
            setButtonLoading(false);
            //console.log(error);
          },
        );
    }

    if (
      filter.uuid_brand === '268307-e4f-4512-5fc3-7a4f1437372' ||
      filter.uuid_brand === '8323a7-4fcf-73b0-e05-6afb671786' ||
      filter.uuid_brand === 'c3538-67be-36c5-7fb-48b3e8e7e3b4'
    ) {
      setButtonLoading(true);
      reportService
        .generateKpiBrandPanelReport(filter.uuid_brand, filter.kpi_year)
        .then(
          res => {
            if (res.status === 'success' && res.file) {
              let a = document.createElement('a');
              a.setAttribute('target', '_blank');
              a.href = configs.file_api_url + '/v1/' + res.file;
              a.download = res.file;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              window.alert(res.message);
            }
            setButtonLoading(false);
          },
          error => {
            setButtonLoading(false);
            //console.log(error);
          },
        );
    }
  };

  return (
    <div className={listReportsStyle.pageContainer}>
      <Label label={`${t('report_list_report')} KPI`} bold neutral />

      <Card bordered>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px">
                <CardRow flexWrap="wrap">
                  <DropdownField
                    name="kpi_year"
                    options={kYear}
                    valueField="kpi_year"
                    labelField="str_name"
                    label={`${t('common_year')}`}
                    margin="10px 10px 0px 0px"
                    value={filter.kpi_year}
                    onChange={handleChange}
                    disabled={false}
                  />

                  <DropdownField
                    name={`uuid_brand`}
                    options={brandsGlobal}
                    valueField="uuid_brand"
                    labelField="str_name"
                    label={`Global Brand`}
                    margin="10px 10px 0px 0px"
                    value={filter.uuid_brand}
                    onChange={handleChange}
                  />
                </CardRow>
              </CardColumn>

              <CardColumn padding="5px">
                <CardRow justifyContent="flex-end" flexWrap="wrap">
                  <Button
                    style={{ zIndex: 0 }}
                    label={t('common_download')}
                    onClick={handleDownload}
                    confirm
                    margin="35px 10px 0px 0px"
                    loading={buttonLoading}
                  />
                </CardRow>
              </CardColumn>
            </CardRow>
          </>
        )}
      </Card>
    </div>
  );
};

export default ListReportsKpi;
